<script setup>
import { onMounted, ref } from 'vue';
import { useAdminStore } from '~/src/stores/admin.store';
import ManageZoneModal from '~/src/Components/ManageZoneModal.vue';
import UIkit from 'uikit';

const adminStore = useAdminStore();

const selectedZoneId = ref(null);
const modalId = '#manage-zones-modal';
let zoneModal;

const addZone = () => {
    selectedZoneId.value = null;

    zoneModal.show();
};

const manageZone = (zoneId) => {
    selectedZoneId.value = zoneId;

    zoneModal.show();
};

const hideZoneModal = () => zoneModal.hide();

onMounted(() => {
    zoneModal = UIkit.modal(modalId);
});
</script>

<template>
    <div class="uk-container uk-container-large uk-border">
        <div class="uk-padding">
            <p class="uk-text-center uk-text-primary uk-text-large"><strong>Please define the areas you are willing to work and provide a complete square foot price for the unit types you are qualified to complete.</strong></p>
            <p class="uk-text-center uk-text-primary uk-text-large uk-text-italic"><strong>Ensure all travel costs are included. Lowen will not pay additional travel costs.</strong></p>
            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
                <h2 class="uk-margin-remove">Manage Zone Pricing</h2>
                <AgileButton type="button" @click="addZone">Add Zone</AgileButton>
            </div>
            <div class="project-list-header uk-visible@m uk-flex uk-flex-between uk-light uk-background-secondary uk-margin-bottom uk-padding-small uk-child-width-1-6">
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Zone Name</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Num of Prices</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Num of Counties</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase"></div>
            </div>
            <div v-for="zone in adminStore.zones" class="record-manager-row uk-visible@m uk-flex uk-flex-between uk-flex-middle uk-margin-bottom uk-padding-small uk-child-width-1-6">
                <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ zone.name }}</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ Array.isArray(zone.prices) ? zone.prices.length : 0 }}</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ Array.isArray(zone.counties) ? zone.counties.length : 0 }}</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase record-actions">
                    <button @click="() => manageZone(zone.seq)" type="button" class="uk-button uk-button-link uk-display-block uk-margin-remove">Edit Zone</button>
                </div>
            </div>
        </div>
        <div id="manage-zones-modal" class="uk-modal-full" uk-modal>
            <ManageZoneModal :zone-id="selectedZoneId" @zone-saved="hideZoneModal" />
        </div>
    </div>
</template>