import { useAdminApi } from '~/src/api/admin';
import { useAdminStore } from '~/src/stores/admin.store';

export const useWebInstallersApi = () => {
    const adminStore = useAdminStore();
    const { apiUrl, makeApiRequest } = useAdminApi();

    const create = async (user) => {
        const url = apiUrl('WebInstallers/Create');
        const responseJson = await makeApiRequest(url, { user });

        return responseJson;
    }

    const fetch = async () => {
        const url = apiUrl("WebInstallers/Fetch");
        const responseJson = await makeApiRequest(url);

        adminStore.set('users', responseJson.Data.users);

        return responseJson;
    };

    const update = async (user) => {
        const url = apiUrl("WebInstallers/Update");
        const responseJson = await makeApiRequest(url, {
            user
        });

        return responseJson;
    };

    return {
        create,
        fetch,
        update
    };
};