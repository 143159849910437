<script setup>
import { onBeforeMount, ref } from 'vue';
import {router} from '../router/router.js';
import { useAuthStore } from '../stores/auth.store.js';
import { useAQStore } from '../stores/aq.store.js';
import { useErrorCheckStore } from '../stores/errorCheck.store';
import { authHeader } from '../router/authheader.js';
import Loading from '../Components/Loading.vue';

const baseUrl = process.env.APIURL;

const location = ref('Lowen Corporation')
const contactNote = 'If any information is incorrect, please contact us at 800.835.2365'

const progress = ref();

const data = ref();
const completedObjects = ref([]);
const incompleteObjects = ref([]);
const aqStore = useAQStore();

onBeforeMount(() => {
    getLocationDetails();
});

function goBack() {
    // console.log("goBack");
    router.go(-1);
}

const getLocationDetails = async () => {
//   loading.value = true;
  const user = useAuthStore().getUser();
//   console.log("Base URL: ", baseUrl);
  const url = baseUrl + "/AQ" + "/GetLocation";
  let returnValue = "";
  let locID = parseInt(localStorage.getItem("LocID"));
  let AQ = localStorage.getItem("currentAQ");
  // Need to send: UserID, AQNumber, LocationID
  let call = {
    "UserID": user.uID,
    "LocationSequence": locID,
    "AQNumber": AQ
    // "ObjectID": 739204  <--- use this one for seeing how tables should look on the backend while debugging
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: authHeader(url),
    body: JSON.stringify(call)
  });
  let checkResponse = useErrorCheckStore().checkStatus(response);
  if (checkResponse){
      const temp = await response.json();
      data.value = temp.Data;
      if (temp.Data == null) {
        // console.log("No data returned from server");
        return;
      }
      sortObjects(temp.Data.Objects);
    
    //   console.log("Data from getLocationDetails: ", data.value);
  }
}

const sortObjects = (objects) => {
    objects.forEach(object => {
        // console.log("object Requires Install: ", object.ReqInstall);
        // console.log("object Requires Removal: ", object.ReqRemoval);
        // console.log("object Install Completed: ", object.InstallCompleted);
        // console.log("object Removal Completed: ", object.RemovalCompleted);

        // why did I do it like this?

        // if (object.ReqInstall == 'True'){
        //     if (object.ReqRemoval == 'True'){
        //         if (object.InstallCompleted == 'True' && object.RemovalCompleted == 'True') {
        //             completedObjects.value.push(object);
        //             return;
        //         } else {
        //             incompleteObjects.value.push(object);
        //             return;
        //         }
        //     }
        //     if (object.InstallCompleted == 'True') {
        //         completedObjects.value.push(object);
        //         return;
        //     } else {
        //         incompleteObjects.value.push(object);
        //         return;
        //     }
        // }
        // if (object.ReqRemoval == 'True'){
        //     if (object.RemovalCompleted == 'True') {
        //         completedObjects.value.push(object);
        //         return;
        //     } else {
        //         incompleteObjects.value.push(object);
        //     }
        // }

        // This is much simpler 

        if (object.ReqInstall == object.InstallCompleted && object.ReqRemoval == object.RemovalCompleted) {
            completedObjects.value.push(object);
            return;
        } else {
            incompleteObjects.value.push(object);
            return;
        }
    })
    // console.log("Completed Objects: ", completedObjects.value);
    // console.log("Incomplete Objects: ", incompleteObjects.value);
    // console.log("Calculating Progress: ", completedObjects.value.length, " / ", objects.length);
    progress.value = Math.round((completedObjects.value.length / objects.length) * 100);
}

const openObject = (object) => {
    // console.log("Opening object: ", object);
    let locID = parseInt(data.value.LocationSequence);
    // console.log("LocID: ", locID);
    useAQStore().setCurrentObjectID(parseInt(object.oObjectID));
    localStorage.setItem('currentObjectID', parseInt(object.oObjectID));
    localStorage.setItem('LocID', locID);
    router.push({ name: 'unitInformation' });
}

const shouldDisable = (object) => {
    let keys = Object.keys(object);
    let newKeys = [];
    let exclude = ['ReqInstall', 'InstallCompleted', 'ReqRemoval', 'RemovalCompleted', 'ObjectID', 'oObjectID'];
    keys.forEach(key => {
            if (!exclude.includes(key)) {
                newKeys.push(key);
            }
    })

    // console.log("Keys after removal: ", newKeys);

    let noValue = true;
    newKeys.forEach(key => {
        if (object[key] != null && object[key] != "") {
            noValue = false;
        }
    })
    return noValue;
}

const genShiftString = () => {
    let shiftString = '';
    // progress.value = 99 ;  // <-- test number shifting with progress bar
    if (progress.value <= 4) {
        shiftString += 'margin-right: auto;';
    } else
    if (progress.value > 4 && progress.value < 96){
        shiftString += 'margin-left: ' + (progress.value - 4) + '%;';
    } else
    if (progress.value >= 96) {
        shiftString += 'margin-left: auto;';
    }
    // if (progress.value == 100) {
    //     shiftString += 'margin-left: auto;';
    // }
    return shiftString;
}
</script>

<style scoped>

</style>

<template>
    <Loading v-if="!data" />
    <div class="uk-container uk-margin-large-bottom">
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>
        <div class="aq-details uk-flex uk-child-width-1-1 uk-child-width-1-2@m uk-margin-remove uk-width-1-1" uk-grid> 
            <!-- poc = Point of contact-->
            <div v-if="data" id="poc-details" class="uk-padding-small uk-padding-remove-vertical">
                <form class="poc-details-container uk-form uk-box-shadow-medium uk-padding uk-margin-top">
                    <h2 class=" uk-text-center"><a class="uk-disabled">{{ data.LocationName }}</a> Information</h2>
                    <hr>
                    <div class="location-container uk-margin-bottom">
                        <h3>Location</h3>
                        <AgileInput v-model="data.LocationName" :id="location" type="text" :readonly="true"/>
                    </div>
                    <div class="address-container uk-child-width-1-1" uk-grid>
                        <h3>Address Information</h3>
                        <AgileInput v-model="data.LocationAddress1" :id="'streetAddress1'" :label="'Street Address'" :type="'text'" :readonly="true"/>
                        <AgileInput v-model="data.LocationAddress2" :id="'streetAddress2'" :label="'Street Address 2'" :type="'text'" :readonly="true" />
                        <AgileInput v-model="data.LocationCity" class="uk-width-1-1 uk-width-1-2@l" :id="'city'" :label="'City'" :type="'text'" :readonly="true" />
                        <AgileInput v-model="data.LocationState" class="uk-width-1-2 uk-width-1-4@l" :id="'state'" :label="'State'" :type="'text'" :readonly="true" />
                        <AgileInput v-model="data.LocationZip" class="uk-width-1-2 uk-width-1-4@l" :id="'zip'" :label="'Zip'" :type="'number'" :readonly="true" />
                    </div>
                    <div class="contact-container uk-child-width-1-1" uk-grid>
                        <h3>Contact Information</h3>
                        <AgileInput v-model="data.LocationContact" :id="'name'" :label="'Name'" :type="'text'" :autocomplete="'name'" :readonly="true"/>
                        <AgileInput v-model="data.LocationPhone" class="uk-width-1-2" :id="'phone'" :label="'Phone'" :type="'text'" :autocomplete="'tel-national'" :readonly="true"/>
                        <AgileInput v-model="data.LocationCell" class="uk-width-1-2" :id="'cell'" :label="'Cell'" :type="'text'" :readonly="true"/>
                        <AgileInput v-model="data.LocationEmail" :id="'email'" :label="'Email'" :type="'text'" :autocomplete="'email'" :readonly="true"/>
                    </div>
                    <div class="dates-container uk-child-width-1-2 uk-flex uk-flex-between" uk-grid>
                        <div>
                            <h3>Actual Start Date</h3>
                            <AgileInput v-model="data.LocationActualStart" class="uk-width-1-1" :id="'startDate'" :type="'date'" :readonly="true" />
                        </div>
                        <div>
                            <h3>Actual Finish Date</h3>
                            <AgileInput v-model="data.LocationActualFinish" class="uk-width-1-1" :id="'finishDate'" :type="'date'" :readonly="true"/>
                        </div>
                    </div>
                    <p class="uk-margin-remove-bottom"><span class="uk-text-danger uk-margin-small-right">*</span>{{ contactNote }}</p>
                </form>
            </div>
    
            <div id="progress" class="uk-padding-small uk-padding-remove-vertical">
                <div class="progress-container uk-box-shadow-medium uk-padding uk-margin-top">
                    <h2 class="uk-text-center">Progress</h2>
                    <hr>
                    <div class="progress-bar-container uk-flex uk-flex-column uk-child-width-1-1" uk-grid>
                        <div class="uk-flex uk-flex-column">
                            <span :style="genShiftString()">{{ progress }} %</span>
                            <progress class="uk-progress" :value="progress" max="100"></progress>
                        </div>
                        <div class="incomplete">
                            <h4>Incomplete Units:</h4>
                            <div class="incomplete-units uk-list uk-child-width-1-3 uk-text-center" uk-grid>
                                <div v-for="object in incompleteObjects" class="uk-margin-small-top">
                                    <button @click="openObject(object)" class="uk-button uk-button-danger uk-width-1-1">
                                        
                                        <!-- <span v-if="shouldDisable(object)" class="uk-margin-remove uk-padding-remove">{{ object.ObjectID }} (Disabled)</span> -->
                                        <span class="uk-margin-remove uk-padding-remove">{{ object.ObjectID }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="completed">
                            <h4>Completed Units:</h4>
                            <div class="completed-units uk-list uk-child-width-1-3 uk-text-center" uk-grid>
                                <div v-for="object in completedObjects" class="uk-margin-small-top">
                                    <button @click="openObject(object)" class="uk-button uk-button-success uk-width-1-1">
                                        <span v-if="object.ObjectID">{{ object.ObjectID }}</span>
                                        <span v-else>{{ object.oObjectID }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>
    </div>
</template>