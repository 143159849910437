<script setup>
import { ref, onMounted, computed, onBeforeMount, watch, provide, inject } from 'vue';
import { useErrorCheckStore } from '../stores/errorCheck.store.js';

let code = computed(() => {return useErrorCheckStore().getCurrentStatusCode});
let errorList = computed(() => {return useErrorCheckStore().getErrorList});
let successList = computed(() => {return useErrorCheckStore().getSuccessList});
let message = computed(() => {return useErrorCheckStore().getCurrentStatusMessage});
let showErrorCode = computed(() => {return useErrorCheckStore().getShowErrorCode});
let messageList = computed(() => {
    let messages = [];
    errorList.value.forEach(error => {
        messages.push(error);
    });
    successList.value.forEach(success => {
        messages.push(success);
    });
    return messages;
})

let show = computed(() => {
    let shouldShow = false;
    // console.log("Error List at computed: ", errorList.value);
    // console.log("Success List at computed: ", successList.value);
    if (errorList.value.length > 0 || successList.value.length > 0){
        shouldShow = true;
    }
    // console.log("Should show: ", shouldShow)
    return shouldShow
});

let handlerClasses = computed(() => {
    let classes = 'error-handler-modal uk-position-relative uk-text-center uk-text-lead uk-flex uk-flex-column uk-flex-center uk-box-shadow-medium';
    if (show.value) {
        classes += ' show-error-handler-modal';
    }
    if (successList.value.length > 0) {
        classes += ' uk-alert-success';
    } 
    if (errorList.value.length > 0) {
        classes += ' uk-alert-danger';
    } 
    return classes;
})

let btnClasses = computed(() => {
    let classes = 'error-handler-button uk-button uk-button-small uk-position-right uk-text-lead';
    if (errorList.value.length > 0) {
        classes += ' uk-alert-danger';
    } 
    if (successList.value.length > 0) {
        classes += ' uk-alert-success';
    } 
    return classes;
})

const toggleShow = () => {
    useErrorCheckStore().clearErrorList();
    useErrorCheckStore().clearSuccessList();
    localStorage.removeItem('forceLogoutError');
}

</script>

<template>
    <div id="ErrorHandlerModal" 
        :class="handlerClasses" >
        <div class="uk-text-lead uk-margin-remove uk-padding-remove uk-flex uk-flex-column"
            :class="{'uk-light' : successList.length <= 0}">
            <span v-for="message in messageList">{{ message }}</span>
        </div>
        <button @click="toggleShow" 
            :class="btnClasses">X</button>
    </div>
</template>