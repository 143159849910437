import { useAdminApi } from '~/src/api/admin';
import { useAdminStore } from '~/src/stores/admin.store';

export const useQualificationsApi = () => {
    const adminStore = useAdminStore();
    const { apiUrl, makeApiRequest } = useAdminApi();

    const fetch = async () => {
        const url = apiUrl('Qualifications/Fetch');
        const responseJson = await makeApiRequest(url);
        
        adminStore.set('qualifications', responseJson.Data);
    };

    const update = async () => {
        adminStore.toggleLoading('qualificationsUpdate');
        
        const url = apiUrl('Qualifications/Update');
        const responseJson = await makeApiRequest(url, {
            qualifications: adminStore.qualifications,
            installer: adminStore.installer
        });

        adminStore.toggleLoading('qualificationsUpdate');
        
        return responseJson;
    };

    return {
        fetch,
        update
    };
};