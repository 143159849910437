<script setup>
import { computed } from 'vue';
import { router } from '~/src/router/router';
import PrimaryLocationTab from '~/src/Components/Tabs/PrimaryLocationTab.vue';
import TaxAndInsuranceTab from '~/src/Components/Tabs/TaxAndInsuranceTab.vue';
import QualificationsTab from '~/src/Components/Tabs/QualificationsTab.vue';

const activeTab = computed(() => {
    const tabs = {
        '#primary-location': 0,
        '#tax-insurance': 1,
        '#qualifications': 2
    };
    const routeHash = router.currentRoute.value.hash;

    return Object.keys(tabs).includes(routeHash) ? tabs[routeHash] : 0;
});
</script>

<template>
    <div class="uk-container uk-container-large uk-flex uk-flex-wrap uk-padding uk-border uk-border-rounded">
        <ul class="uk-tab-right uk-width-1-5@m uk-padding uk-padding-remove-horizontal uk-margin-remove" :uk-tab="`active: ${activeTab}`">
            <li><a href="#primary-location">Primary Location</a></li>
            <li><a href="#">Tax &amp; Insurance</a></li>
            <li><a href="#">Qualifications</a></li>
        </ul>
        <div class="uk-switcher uk-width-1-1 uk-width-4-5@m uk-padding uk-background-default" id="business-details">
            <PrimaryLocationTab />
            <TaxAndInsuranceTab />
            <QualificationsTab />
        </div>
    </div>
</template>