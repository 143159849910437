<script setup>
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import {router} from '../router/router';
import {authHeader} from '../router/authheader';
import {useAuthStore} from '../stores/auth.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store';
import AgileButton from '../Components/AgileComponents/AgileButton.vue';

const baseUrl = process.env.APIURL + "/Users";
const user = ref();
const newPassword = ref('');
const confirmPassword = ref('');
const authStore = useAuthStore();

const successMessage = ref("");
const passwordSuccessMessage = ref("");
const invalidPassword = ref(false);



const updatePassword = async () => {
    // console.log("newPassword: ", newPassword.value);
    // console.log("confirmPassword: ", confirmPassword.value);
    if (useErrorCheckStore().validatePassword(newPassword.value, confirmPassword.value)){
        let returnValue = "";
        let call = {
            "UserID": user.value.uID,
            "NewPassword": newPassword.value
        };
        const url = baseUrl + "/updatePassword";
        // console.log("Update Password: ", call);
        const response = await fetch(url, {
            method: 'POST',
            headers: authHeader(url),
            body: JSON.stringify(call)
        });

        // let checkResponse = useErrorCheckStore().checkStatus({status: 500, statusText: "Internal Server Error"});
        let checkResponse = useErrorCheckStore().checkStatus(response);
        if (checkResponse){
            returnValue = await response.json();
            useErrorCheckStore().checkStatusJSON(returnValue, true);
        }
    } else {
        // console.log("Invalid password")
        // console.log("newPassword: ", newPassword.value);
        // console.log("confirmPassword: ", confirmPassword.value);
        invalidPassword.value = true;
    }
    
    newPassword.value = "";
    confirmPassword.value = "";
}

const updateInfo = async () => {
    if (newPassword.value === confirmPassword.value){
        let returnValue = "";
        let call = {
            "UserID": user.value.uID,
            "FirstName": user.value.FirstName,
            "LastName": user.value.LastName,
            "Email": user.value.Username
        };
        const url = baseUrl + "/updateUserInfo";
        
        // console.log("Update Profile: ", call);
        const response = await fetch(url, {
            method: 'POST',
            headers: authHeader(url),
            body: JSON.stringify(call)
        });
        let checkResponse = useErrorCheckStore().checkStatus(response);
        if (checkResponse){
            returnValue = await response.json();
            useErrorCheckStore().checkStatusJSON(returnValue, true);
        }
    }
}


function goBack() {
    // console.log("goBack");
    router.go(-1);
}

onBeforeMount(() => {
    // console.log("Profile.vue beforeMount");
    user.value = useAuthStore().user;
});

onMounted(() => {
    // console.log("Profile.vue mounted");
    // console.log("User: ", user.value);
    // log();
});

</script>

<template>
    <div class="uk-container">
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>
        <div v-if="user" class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m uk-margin-small-left" uk-grid>
            <div class="uk-padding-small uk-padding-remove-left">
                <div class="uk-flex uk-flex-column uk-box-shadow-medium uk-padding">
                    
                    <h3 class="uk-text-center">User Profile</h3>
                    <hr>
                    <div class="uk-list uk-flex uk-flex-column uk-margin-small-bottom">
                        <label for="firstName">First Name</label>
                        <input class="uk-input uk-margin-remove" type="text" id="firstName" v-model="user.FirstName" />
                    </div>
                    <div class="uk-list uk-flex uk-flex-column uk-margin-small-bottom">
                        <label for="lastName">Last Name</label>
                        <input class="uk-input uk-margin-remove" type="text" id="lastName" v-model="user.LastName" />
                    </div>
                    <div class="uk-list uk-flex uk-flex-column uk-margin-small-bottom">
                        <label for="email" id="user">Email Address</label>
                        <input class="uk-input uk-margin-remove" type="email" id="email" ref="user.FirstName" v-model="user.Username"/>
                    </div>
                    <div v-if="successMessage" class="update-message-container">
                        <p class="uk-margin-remove uk-text-center uk-text-bold uk-alert-success uk-padding-small uk-text-primary">{{ successMessage }}</p>
                    </div>
                    <div class="uk-list uk-margin-top">
                        <!-- <div class="uk-position-relative uk-light">
                            <button @click="updateInfo" class="uk-input uk-margin-remove uk-button-primary project-button-small uk-width-1-1"> Update Profile</button>
                        </div> -->
                        <AgileButton class="uk-width-1-1" @click="updateInfo">Update Profile</AgileButton>
                    </div>
    
                </div>
            </div>
            <div class="uk-padding-small uk-padding-remove-left">
                <div class="uk-flex uk-flex-column uk-box-shadow-medium uk-padding uk-margin-remove-top">
                    <h3 class="uk-text-center">Password Reset</h3>
                    <hr>
                    <form>
                        <div class="uk-list uk-flex uk-flex-column uk-margin-small-bottom">
                            <label for="newPassword">New Password</label>
                            <input class="uk-input uk-margin-remove" :class="{'error-border': invalidPassword}" type="password" id="newPassword" autocomplete="false"  v-model="newPassword"/>
                        </div>
                        <div class="uk-list uk-flex uk-flex-column uk-margin-small-bottom">
                            <label for="confirmPassword">Confirm Password</label>
                            <input class="uk-input uk-margin-remove" :class="{'error-border': invalidPassword}" type="password" id="confirmPassword" autocomplete="false" v-model="confirmPassword"/>
                        </div>
                        <!-- <div v-if="passwordSuccessMessage" class="update-message-container">
                            <p class="uk-margin-remove uk-text-center uk-text-bold uk-alert-success uk-padding-small uk-text-primary">{{ passwordSuccessMessage }}</p>
                        </div> -->
                        <div class="uk-list uk-margin-top">
                            <!-- <div class="uk-position-relative uk-light">
                                <button @click.prevent.default="updatePassword" class="uk-input uk-margin-remove uk-button-primary project-button-small uk-width-1-1">Update Password</button>
                            </div> -->
                            <AgileButton class="uk-width-1-1" @click.prevent.default="updatePassword">Update Password</AgileButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        <div class="uk-margin-top">
            <a class="uk-text-secondary" href="#" @click="goBack"><span class="uk-text-secondary" uk-icon="icon: chevron-double-left"></span> Back</a>
        </div>
    </div>
</template>