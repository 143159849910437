<script setup>
const emit = defineEmits(['editLocation']);
const props = defineProps({
    location: Object
});
</script>

<template>
    <div class="record-manager-row uk-visible@m uk-flex uk-flex-between uk-flex-middle uk-margin-bottom uk-padding-small uk-child-width-1-6">
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.name }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.address }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.city}}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.state }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.zip_code }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ location.country }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase record-actions">
            <button class="uk-button uk-button-link uk-display-block uk-margin-remove" type="button" @click="() => emit('editLocation', location)">Edit Location</button>
        </div>
    </div>
</template>