<script setup>
import {ref, watch, computed, onBeforeMount} from 'vue';
import {useAuthStore} from '../stores/auth.store.js';
import {useAQStore} from '../stores/aq.store.js';
import {useErrorCheckStore} from '../stores/errorCheck.store.js';
import Search from '../Components/Search.vue';
import {router} from '../router/router.js';
import {authHeader} from '../router/authheader.js';
import Loading from '../Components/Loading.vue';
import ProjectList from '../Components/ProjectList.vue';

let loading = ref(true);
let baseUrl = process.env.APIURL + "/AQ";
let currentAQ = ref(useAQStore().getCurrentAQ);
let user = computed(() => useAuthStore().user);
let userDetails = ref();
let tableHeaders = ref(['AQ', 'Project', 'Date', 'PO#', 'Coordinator', 'Status']);
let searchText = ref('');
// set to null to prevent table trying to render without data
let tableData = ref([]);
let toggleClosed = ref(false);

onBeforeMount(async () => {
  console.log("Mount Projects");
  userDetails.value = await getUserDetails();
  tableData.value = await getTableData();
});

watch(toggleClosed, (newValue, oldValue) => {
  console.log(`toggleClosed changed from ${oldValue} to ${newValue}`);
});

let getUserDetails = async () => {
  if (user.value) {
    let returnValue = "";
    let call = {
      "uId": user.value.uID
    };
    let url = process.env.APIURL + "/Users/userDetails";
    let response = await fetch(url, {
      method: 'POST',
      headers: authHeader(url),
      body: JSON.stringify(call)
    })
    // console.log("Response: ", response);
    // let checkResponse = useErrorCheckStore().checkStatus({status: 500, statusText: "Internal Server Error"});

    let checkResponse = useErrorCheckStore().checkStatus(response);
    if (checkResponse) {
      returnValue = await response.json();
      let checkData = useErrorCheckStore().checkStatusJSON(returnValue);
      if (checkData) {
        let data = returnValue.Data;
        return data;
      } else {
        // console.log("Stopping loading animation")
        return false;
      }
    }
  }
}

async function getTableData() {
  if (user.value) {
    let returnValue = "";
    let call = {
      "uId": user.value.uID
    };
    let url = baseUrl + "/FetchAQs";
    let response = await fetch(url, {
      method: 'POST',
      headers: authHeader(url),
      body: JSON.stringify(call)
    });
    // let checkResponse = useErrorCheckStore().checkStatus({status: 400, statusText: "Bad Request"});
    let checkResponse = useErrorCheckStore().checkStatus(response);
    if (checkResponse) {
      returnValue = await response.json();
      let checkData = useErrorCheckStore().checkStatusJSON(returnValue, false, true);
      if (checkData) {
        // console.log("Table Data: ", returnValue.Data)
        let data = returnValue.Data;
        loading.value = false;
        // handleTableData(data);
        // console.log("Table Data value: ", tableData)
        return data.AQObjects;
      }
    }
    loading.value = false;
  }
}

//DEPRECATED IN FAVOR OF SEARCHTEXT(?)
/*let filteredData = computed(() => {
  console.log("projects filteredData");
  let newData = []
  // console.log("Search Text: ", searchText.value);
  if (searchText.value && tableData) {
    for (let i = 0; i < tableData.value.length; i++) {
      let item = tableData.value[i];
      // console.log("Item: ", item);
      for (let [key, value] of Object.entries(item)) {
        // console.log(`${key}: ${value}`);
        if (value.includes(searchText.value)) {
          // console.log("Found: ", value);
          newData.push(item)
        }
      }
    }
  }

  if (newData.length > 0) {
    return newData;
  } else {
    newData = tableData.value;
    return newData;
  }
});*/

let emptyTable = computed(() => {
  let empty = false;
  if (tableData.value) {
    if (tableData.value.length < 1) {
      empty = true;
    }
  } else {
    empty = true;
  }
  return empty;
});

let setCurrentAQ = ((item) => {
  // console.log("Item ", item);
  // console.log("Item PO: ", item.PO);
  useAQStore().setCurrentAQ(item["AQ"]);
  useAQStore().setCurrentPO(item["PO"]);
  useAQStore().setCurrentProject(item.Project);
  // console.log("Current AQ: ", useAQStore().currentAQ);
  // console.log("Current PO: ", useAQStore().currentPO);
  // console.log("Current Project: ", useAQStore().currentProject);
  router.push({ name: 'AQ' });
})
let formatHeader = ((header) => {
  header = header.replace(/ /g, '-');
  header = header.replace(/\//g, '');
  header = header.replace(/&/g, 'and');
  return header;
});

</script>


<template>
  <section id="Projects" class="uk-container">
    <Loading v-if="loading"/>

    <div v-if="user" class="uk-margin-top uk-form uk-box-shadow-medium uk-padding">
      <h2 class="uk-text-center">PROJECT LIST</h2>
      <hr>
      <!------------------------------------------------------------------------------------------------------------------------------------>
      <p>{{ user.InstallerName }}</p>
      <div class="uk-flex uk-flex-between" uk-grid>
        <div class="">
          <Search class="uk-width-1-1 uk-width-medium@s" :ID="'Project-Search'" v-model="searchText" :Shadow="true"/>
        </div>
        <div class="uk-text-large">
          <label class="" for="ShowClosed">Show Closed
            <input class="uk-checkbox" v-model="toggleClosed" type="checkbox" id="ShowClosed" name="ShowClosed"/>
          </label>
        </div>
      </div>

      <ProjectList
          class="min-height"
          v-model="searchText"
          :ID="'ProjectsProjectList'"
          :Data="tableData"
          :ToggleKey="'AQ'"
          :FetchKey="'PO'"
          :ClosedKey=toggleClosed
          :EndPoint="'/AQ/GetByPOUnsorted'"
          :Headers="[
                    {
                        key: 'AQ',
                        ratio: 1,  // ratio is 1 - 4, 4 representing the whole width of the parent, 1 representing 1/4th of the width of the parent/ Total between headers should = 4
                        column: 0
                    },
                    {
                        key: 'PO',
                        ratio: 1,
                        column: 1
                    }, 
                    {
                        key: 'Project',
                        ratio: 2,
                        column: 2
                    }, 
                    {
                        key: 'Status',
                        ratio: 1,
                        column: 3
                }]"
          :MobileHeaders="[
                    {
                        key: 'AQ',
                        ratio: 1,
                        column: 0
                    },
                    {
                        key: 'Project',
                        ratio: 2,
                        column: 1
                    }
                ]"
      />
      <div v-if="emptyTable && !loading">
        <p class="uk-text-center uk-text-italic">No Projects Found</p>
      </div>
    </div>
  </section>
</template>