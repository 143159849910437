<script setup>
import { computed, onMounted, ref } from 'vue';
import { useAdminStore } from '~/src/stores/admin.store';
import { useWebInstallersApi } from '~/src/api/webInstallers';
import ManageUsersRow from '~/src/Components/ManageUsersRow.vue';
import UIkit from 'uikit';

let modal;

const adminStore = useAdminStore();

const webInstallers = useWebInstallersApi();

const webInstallerProperties = {
    firstname: '',
    lastname: '',
    email: '',
    location_id: ''
};

const webInstaller = ref(webInstallerProperties);

const webInstallerSaveMethod = computed(() => webInstaller.value.uID_web_inst_user != undefined && webInstaller.value.uID_web_inst_user != false ? 'update' : 'create');

const addWebInstaller = () => {
    webInstaller.value = webInstallerProperties;

    modal.show();
};

const editWebInstaller = (editingWebInstaller) => {
    webInstaller.value = {...webInstallerProperties, ...editingWebInstaller};

    modal.show();
};

const fetchWebInstallersAndHideModal = () => {
    webInstallers.fetch();

    modal.hide();
};

const saveWebInstaller = () => {
    webInstallers[webInstallerSaveMethod.value](webInstaller.value).then((response) => {
        if (!response.Data.success) {
            return console.error("Error while adding new web installer");
        }

        fetchWebInstallersAndHideModal();
    });
};

onMounted(() => {
    modal = UIkit.modal(document.querySelector('#web-installer-modal'));
})
</script>

<template>
    <div class="uk-container uk-container-large uk-border">
        <div class="uk-padding">
            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
                <h2 class="uk-margin-remove">Manage Users</h2>
                <AgileButton type="button" @click="addWebInstaller">Add User</AgileButton>
            </div>
            <div class="project-list-header uk-visible@m uk-flex uk-flex-between uk-light uk-background-secondary uk-margin-bottom uk-padding-small uk-child-width-1-6">
                <div class="uk-text-center uk-text-bold uk-text-uppercase">First Name</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Last Name</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Email</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Location</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Role</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase"></div>
            </div>
            <ManageUsersRow v-for="(user, i) in adminStore.users" :key="i" :user="user" @edit-user="editWebInstaller" />
        </div>
        <div id="web-installer-modal" class="uk-modal-container" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-header uk-padding uk-flex uk-flex middle uk-flex-between">
                    <h2 class="uk-margin-remove uk-modal-title">
                        <span v-if="webInstallerSaveMethod == 'update'">Edit</span><span v-if="webInstallerSaveMethod == 'create'">Add</span> User
                    </h2>
                    <button class="uk-modal-close-full uk-close-large uk-border-circle" type="button" uk-close></button>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>
                        <div class="uk-padding uk-padding-remove-vertical">

                            <div class="uk-margin-small-bottom">
                                <label for="firstname">First Name</label>
                                <input type="text" name="firstname" id="firstname" class="uk-input uk-margin-remove" v-model="webInstaller.firstname">
                            </div>

                            <div class="uk-margin-small-bottom">
                                <label for="lastname">Last Name</label>
                                <input type="text" name="lastname" id="lastname" class="uk-input uk-margin-remove" v-model="webInstaller.lastname">
                            </div>

                        </div>
                        
                        <div class="uk-padding uk-padding-remove-vertical">

                            <div class="uk-margin-small-bottom">
                                <label for="email">Email</label>
                                <input type="email" name="email" id="email" class="uk-input uk-margin-remove" v-model="webInstaller.email">
                            </div>

                            <div class="uk-margin-small-bottom">
                                <label for="location">Location</label>
                                <select name="location" id="location" class="uk-select" v-model="webInstaller.location_id">
                                    <option value="">Select a Location</option>
                                    <option v-for="location in adminStore.locations" :value="location.cust_key"><strong>{{ location.name }}</strong> ({{ location.address }})</option>
                                </select>
                            </div>
                            
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-right">
                        <AgileButton type="button" @click="saveWebInstaller">Save</AgileButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>