import { ref } from 'vue';

export const usePrice = (initValue = {}) => {
    const zone_seq = ref(initValue.zone_seq);
    const unit_type = ref(initValue.unit_type ?? '');
    const coverage_type = ref(initValue.coverage_type ?? '');
    const price = ref(initValue.price ?? 0.00);

    return {
        zone_seq,
        unit_type,
        coverage_type,
        price
    }
}