<script setup>
const emit = defineEmits(['editUser']);
const props = defineProps({
    user: Object
});
</script>

<template>
    <div class="record-manager-row uk-visible@m uk-flex uk-flex-between uk-flex-middle uk-margin-bottom uk-padding-small uk-child-width-1-6">
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ user.firstname }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ user.lastname }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ user.email }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase"> ----- </div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase">{{ user.level }}</div>
        <div class="uk-text-center uk-text-bold uk-text-uppercase record-actions">
            <button class="uk-button uk-button-link uk-display-block uk-margin-remove" type="button">Actions <span uk-drop-parent-icon></span></button>
            <div uk-dropdown="mode: hover; delay-hide: 0" class="uk-background-default" :class="querySelector">
                <ul class="uk-nav uk-dropdown-nav">
                    <li><button class="uk-button uk-button-link uk-display-block" type="button" @click="() => emit('editUser', props.user)">Edit User</button></li>
                    <li><button class="uk-button uk-button-link uk-display-block" type="button">Reset Password</button></li>
                </ul>
            </div>
        </div>
    </div>
</template>