<script setup>
import { onMounted, ref, toRef, toValue, watch } from 'vue';
import { circle, featureCollection, flip, intersect, lineToPolygon, point } from '@turf/turf';
import { useAdminStore } from '~/src/stores/admin.store';
import { useErrorCheckStore } from '~/src/stores/errorCheck.store.js';
import { useMapSearch } from '~/src/composables/mapSearch';
import { usePrice } from '~/src/composables/price';
import { useZone } from '~/src/composables/zones';
import { useZonesApi } from '~/src/api/zones';
import { SHAPE_TYPE_COUNTIES, SHAPE_TYPE_DISTANCE } from '~/src/composables/zones';
import AgileStateSelector from '~/src/Components/AgileComponents/AgileStateSelector.vue';
import * as atlas from 'azure-maps-control';
import * as drawing from 'azure-maps-drawing-tools';
import * as spatial from "azure-maps-spatial-io";
import 'azure-maps-control/dist/atlas.min.css';
import "azure-maps-drawing-tools/dist/atlas-drawing.min.css";

const emit = defineEmits(['zoneSaved']);
const props = defineProps({
    zoneId: Number
});


/** ----- APIs ----- */
const search = useMapSearch(process.env.AzureMapsApiKey);
const zones = useZonesApi();


/** ----- Pinia Stores ----- */
const adminStore = useAdminStore();


/** ----- Map ----- */
const countyBoundariesFile = 'cleaned_cb_2023_us_county_500k.kml';
const mapStartingPoint = [-98.583333, 39.833333];

const countyColors = {
    enabled: {
        stroke: '#47dc99',
        fill: '#22b573'
    },
    disabled: {
        stroke: '#0071bc',
        fill: '#0a9dff'
    }
};

const colorOptions = {
    stroke: [
        'match',
        ['get', 'active'],
        'true', countyColors.enabled.stroke,
        'false', countyColors.disabled.stroke,
        countyColors.disabled.stroke
    ],
    fill: [
        'match',
        ['get', 'active'],
        'true', countyColors.enabled.fill,
        'false', countyColors.disabled.fill,
        countyColors.disabled.fill
    ]
};

const clickToSelectEnabled = ref(false);
const loadingMap = ref(false);
const shapeDrawingEnabled = ref(false);
const zone = toRef(useZone());
const zoneCenter = ref(null);

let dataSource, drawingManager, layer, popup, map;

const activateCountiesWithinShape = (e) => {
    console.info('Activating counties within shape');
    console.debug(e);
    
    let drawnShape = Object.hasOwn(e, 'circlePolygon') ? e.circlePolygon : e.data;

    if (drawnShape.geometry.type == 'LineString') {
        drawnShape = lineToPolygon(drawnShape);
    }

    const countyShapes = dataSource.getShapes();

    const countiesInShape = countyShapes.filter((shape) => intersect(featureCollection([drawnShape, shape.data])));

    countiesInShape.forEach((countyShape) => zone.value.toggle(countyShape));

    drawnShape = new atlas.Shape(drawnShape);
    drawnShape.addProperty('shape_type', 'zone_shape');

    zone.value.map_data.push(drawnShape);

    console.info('Finished activating counties within shape');
};

const addMapFeatures = (features, featureType = 'feature') => {
    const featureShapes = features.map((f) => {
        const featureName = f.properties.name.replace(/ /g, "_").replace(/[^\w-]+/g, "").toLowerCase();
        f.id = `installer_${useAdminStore().installerId}_${featureType}_${featureName}_${f.properties.STUSPS.value}`;

        const shape = new atlas.Shape(f);
        shape.addProperty('active', 'false');
        shape.addProperty('shape_type', 'zone_county');

        return shape;
    });

    dataSource.add(featureShapes);
};

const addMapShape = (shape) => {
    console.info('Adding map shape');

    drawingManager.getSource().add(shape);
    zone.value.add(shape);
};

const addMapShapes = (shapes) => {
    if (!Array.isArray(shapes) || shapes.length == 0) {
        return useErrorCheckStore().pushError('"Shapes" must be of type "Array"');
    }

    clearMapShapes();
    if (zone.value.hasShapes) {
        zone.value.clearMapData();
    }

    if (zone.value.hasCounties) {
        zone.value.clearCounties();
    }

    shapes.forEach((shape) => addMapShape(shape));
};

const clearMapFeatures = () => {
    if (dataSource != null && dataSource != undefined) {
        console.info('Clearing map features');
        dataSource.clear();
    }
};

const clearMapShapes = () => {
    if (drawingManager != null && drawingManager != undefined) {
        console.info('Clearing map shapes');
        drawingManager.getSource().clear();
    }
};

const convertLocationToCenterPoint = (e) => {
    console.info("Converting location address into coordinates for center point");
    console.debug(zone.value.ContactKey);
    const location = adminStore.getLocationByKey(zone.value.ContactKey);
    console.debug(location);
    
    return search.forAddress(location).then((json) => {
        convertResultToCenterPoint(json.results[0]);

        console.info("Displaying counties near address search result");
        showCountyBoundariesFor(json.results[0].address.countrySubdivisionName);
    });
};

const convertResultToCenterPoint = (result) => {
    console.info("Converting address search result to point on map");
    const resultCenterPoint = createCenterPoint(Object.values(result.position));

    zoneCenter.value = new atlas.Shape(flip(resultCenterPoint), 'zone_center_point');
    zoneCenter.value.addProperty('shape_type', 'zone_shape');

    addMapShape(zoneCenter.value);
    setMapCenter(zoneCenter.value);
};

const createCenterPoint = (coords) => point(coords, {}, { id: 'zone_center_point' });

const deactiveAllMapFeatures = () => {
    console.info("Deactivating all map features");

    for (const feature of dataSource.getShapes()) {
        if (Object.hasOwn(feature.data.properties, 'active') && feature.data.properties.active != 'false') {
            console.info("Deactivating map feature");
            console.debug(feature);
            feature.addProperty('active', 'false');
        }
    }
};

const disableShapeClickToSelect = () => {
    if (map != null && map != undefined) {
        map.events.remove('click', layer, shapeClickHandler);
    }

    clickToSelectEnabled.value = false;

    disableShapePopuup();
};

const disableShapeDrawing = () => {
    if (drawingManager != null && drawingManager != undefined) {
        drawingManager.setOptions({ mode: 'idle' });
    }

    shapeDrawingEnabled.value = false;
};

const disableShapePopuup = () => {
    if (popup != null && popup != undefined) {
        popup.close();
    }

    if (map != null && map != undefined) {
        map.events.remove('mousemove', layer, showPopupOnHover);
        map.events.remove('touchend', layer, showPopupOnHover);
    }
};

const drawShape = (shapeType) => {
    console.info('Drawing shape...');
    disableShapeClickToSelect();

    clearMapShapes();

    enableShapeDrawing(shapeType);

    map.events.addOnce('drawingcomplete', drawingManager, async (e) => {
        console.info('Drawing complete');
        disableShapeDrawing();
        
        if (shapeSelector.value == SHAPE_TYPE_COUNTIES) {
            deactiveAllMapFeatures();
            activateCountiesWithinShape(e);
        }
    });
};

const enableShapeClickToSelect = (clickToSelectOptions = { showPopup: false }) => {
    console.info('Enabling shape click-to-select');
    console.debug('Click-to-select options:');
    console.debug(clickToSelectOptions);

    map.events.add('click', layer, shapeClickHandler);

    clickToSelectEnabled.value = true;

    if (Object.hasOwn(clickToSelectOptions, 'showPopup') && clickToSelectOptions.showPopup == true) {
        enableShapePopup();
    }
};

const enableShapeDrawing = (shape) => {
    drawingManager.setOptions({ mode: `draw-${shape}` });

    shapeDrawingEnabled.value = true;
};

const enableShapePopup = () => {
    console.info('Enabling shape popup');

    map.events.add('mousemove', layer, showPopupOnHover);
    map.events.add('touchend', layer, showPopupOnHover);
};

const finishDrawingShape = () => {
    const drawnShape = drawingManager.getSource().getShapes()[0];

    if (!drawnShape) {
        disableShapeDrawing();
    } else {
        map.events.invoke('drawingcomplete', drawingManager, drawnShape);
    }
};

const generateShape = () => {
    console.info('Generating shape for zone center and radius');

    loadingMap.value = true;

    const zoneCircle = circle(zoneCenter.value.getCoordinates(), zone.value.radius, {
        units: 'miles',
        properties: {
            id: 'zone_circumference'
        }
    });

    const zoneCircleShape = new atlas.Shape(zoneCircle.geometry, 'zone_circumference');
    zoneCircleShape.addProperty('shape_type', 'zone_shape');

    addMapShapes([
        zoneCircleShape,
        zoneCenter.value
    ]);

    activateCountiesWithinShape(zoneCircleShape);

    loadingMap.value = false;
};

const initializeDrawingManager = () => {
    console.debug("Initializing Drawing Manager");

    drawingManager = new drawing.drawing.DrawingManager(map);

    const drawingLayers = drawingManager.getLayers();

    if (Object.hasOwn(drawingLayers, 'lineLayer')) {
        drawingLayers.lineLayer.setOptions({ strokeColor: colorOptions.stroke });
    }

    if (Object.hasOwn(drawingLayers, 'polygonLayer')) {
        drawingLayers.polygonLayer.setOptions({ fillColor: colorOptions.fill });
    }

    console.debug("Finished initializing Drawing Manager");
};

const initializeMap = () => {
    console.debug("Initalizing map");
    map = new atlas.Map('location-zone-map', {
        authOptions: {
            authType: 'subscriptionKey',
            subscriptionKey: process.env.AzureMapsApiKey
        },
        language: 'en-US',
        center: mapStartingPoint,
        zoom: 4
    });

    map.setUserInteraction({
        boxZoomInteraction: false,
        dblClickZoomInteraction: false,
        dragRotateInteraction: false,
        touchRotate: false
    });

    map.events.addOnce('ready', () => {
        console.debug("Map is ready");
        initializeDrawingManager();
        initializeMapDataLayers();
    });
};

const initializeMapDataLayers = () => {
    console.debug("Initializing Map Data Layers");

    dataSource = new atlas.source.DataSource("lowen-data-source");
    map.sources.add(dataSource);

    layer = new spatial.layer.SimpleDataLayer(dataSource, { enablePopups: false });
    layer.getLayers().lineLayer.setOptions({ strokeColor: colorOptions.stroke });
    layer.getLayers().polygonLayer.setOptions({ fillColor: colorOptions.fill });

    map.layers.add(layer);

    console.debug("Finished intializing Map Data Layers");
};

const initializePopup = () => {
    if (!popup) {
        console.info('Initializing map popup');

        popup = new atlas.Popup({ position: [[0, 0]] });
    }
};

const populateMapFromZone = async () => {
    console.info("Popuplating map with zone data");
    resetMap();

    if (!map) {
        initializeMap();
    }

    if (zone.value.ContactKey != null && zone.value.ContactKey != undefined) {
        selectDistanceForZone();
        await convertLocationToCenterPoint();
    } else if (zone.value.state != null && zone.value.state != undefined) {
        selectCountiesForZone();
        await showCountyBoundariesFor(zone.value.state);
    }

    // Add selected shapes from DB
    for (const shape of zone.value.map_data) {
        const mapShape = new atlas.Shape(shape.data.geometry, shape.data.id, shape.data.properties);

        if (Object.hasOwn(mapShape.data.properties, 'shape_type')) {
            if (mapShape.data.properties.shape_type == 'zone_county') {
                // Add to data source
                const existingShape = dataSource.getShapeById(mapShape.data.id);

                if (existingShape != null && existingShape != undefined) {
                    console.info('Removing `zone_county` shape from data source');
                    console.debug(existingShape);
                    dataSource.remove(existingShape);
                }

                console.info('Adding map shape to data source');
                console.debug(mapShape);
                dataSource.add(mapShape)
            } else {
                // Add to drawing manager
                console.info('Adding map shape to drawing manager');
                console.debug(mapShape);
                drawingManager.getSource().add(mapShape);
            }
        }
        
    }

    if (!zone.value.seq) {
        setMapCenter(mapStartingPoint, 4);
    }
};

const removeCounty = (county) => {
    console.info("Removing county selection");
    console.debug(county);
    if (dataSource) {
        const removedShape = dataSource.getShapes().find((shape) => {
            return shape.data.properties.NAME.value == county.county && shape.data.properties.STATE_NAME.value == county.state;
        });

        if (removedShape !== undefined) {
            // This will call 'removeCounty' for us
            zone.value.removeMapShape(removedShape);
        }
    }
};

const setMapCenter = (centerPoint, zoom = 7) => {
    let mapCenter = centerPoint;

    if (Object.hasOwn(centerPoint, 'data')) {
        mapCenter = centerPoint.data.geometry.coordinates;
    }

    if (Object.hasOwn('geometry')) {
        mapCenter = centerPoint.geometry.coordinates;
    }

    map.setCamera({
        center: mapCenter,
        duration: 1000,
        type: "fly",
        zoom
    });
};

const shapeClickHandler = (e) => zone.value.toggle(e.shapes[0]);

const showCountyBoundariesFor = (state) => {
    console.info(`Showing county boundaries for state: ${state}`);

    const search = useMapSearch(process.env.AzureMapsApiKey);

    return search.forState(state).then(async (json) => {
        const stateBounds = atlas.data.BoundingBox.fromLatLngs(Object.values(json.results[0].boundingBox));

        await showKmlOverlay({
            kml: countyBoundariesFile,
            filterCallback: (r) => r.features.filter((feature) => {
                return atlas.data.BoundingBox.intersect(stateBounds, new atlas.Shape(feature).getBounds());
            }),
            featureType: 'county'
        });
    });
};

const showKmlOverlay = async (overlay) => {
    console.info('Showing KML overlay');
    loadingMap.value = true;

    console.info('Clearing map features');
    clearMapFeatures();

    const r = await spatial.io.read(`/public/${overlay.kml}`, { parseStyles: false });
    console.info(`Finished reading file at: '/public/${overlay.kml}'`);
    
    if (!r) {
        return useErrorCheckStore().pushError('Could not read data from KML file');
    }

    if (!Array.isArray(r.features) || r.features.length == 0) {
        return useErrorCheckStore().pushError('Could not parse map features from KML file');
    }
    const featureData = typeof overlay.filterCallback == 'function' ? overlay.filterCallback(r) : r.features;

    console.info("Adding KML features to map");
    console.debug(featureData);
    if (!Array.isArray(featureData)) {
        return useErrorCheckStore().pushError('`FilterCallback` did not return an array');
    }

    addMapFeatures(featureData, overlay.featureType);
    
    const featureBounds = atlas.data.BoundingBox.fromData(dataSource.getShapes());

    map.setCamera({ bounds: featureBounds });

    loadingMap.value = false;
};

const showPopupOnHover = (e) => {
    if (!popup) {
        initializePopup();
    }

    popup.close();

    if (e.shapes && e.shapes.length > 0) {
        const shape = e.shapes[0];
        const properties = shape.getProperties();

        let popupPos = atlas.data.BoundingBox.getCenter(shape.getBounds())
        popupPos = [popupPos[0], popupPos[1] + .1];

        popup.setOptions({
            content: `<div style="padding: 10px;"><strong>${properties.name}</strong></div>`,
            position: popupPos
        });

        popup.open(map);
    }
};


/** ----- Sidebar ----- */
const shapeSelector = ref(null);


/** ----- Methods ----- */
const fetchZonesAndCloseModal = () => zones.fetch().then(() => {
    emit('zoneSaved');
    resetModal();
});

const deleteZone = () => zones.destroy(zone.value.seq).then((response) => fetchZonesAndCloseModal());

const getZone = () => {
    if (props.zoneId == null || props.zoneId == undefined) {
        resetModal();
    } else {
        zone.value = useZone(adminStore.zones.find((zone) => zone.seq == toValue(() => props.zoneId)));

        populateMapFromZone();
    }
};

const saveZone = () => zones.save(zone.value.data()).then((response) => fetchZonesAndCloseModal());

const setShapeSelector = (selectorType) => shapeSelector.value = selectorType;

const selectCountiesForZone = () => {
    setShapeSelector(SHAPE_TYPE_COUNTIES);
};

const selectDistanceForZone = () => {
    setShapeSelector(SHAPE_TYPE_DISTANCE);
};

const resetMap = () => {
    setShapeSelector(null);
    clearMapFeatures();
    clearMapShapes();
    disableShapeClickToSelect();
    disableShapeDrawing();
};

const resetModal = () => {
    zone.value = useZone();
    resetMap();
    setMapCenter(mapStartingPoint, 4);
};

const resetZoneSelections = () => {
    resetMap();
    zone.value.resetSelections();
    setMapCenter(mapStartingPoint, 4);
};

const coverageTypeExhausted = (unit_type_seq, coverage_type_seq) => zone.value.prices.filter((price) => price.unit_type == unit_type_seq).some((price) => price.coverage_type == coverage_type_seq);

const unitTypeExhausted = (unit_type_seq) => zone.value.prices.filter((price) => price.unit_type == unit_type_seq).length == adminStore.coverage_types.length;


/** ----- Vue functions ----- */
onMounted(initializeMap);

watch(() => props.zoneId, (newZoneId, oldZoneId) => {
    console.info('Zone Watcher was triggered');
    getZone();
});
</script>

<template>
    <div class="uk-modal-dialog">
        <div class="uk-modal-header uk-padding uk-padding-remove-vertical uk-flex uk-flex-middle uk-flex-between">
            <h2 class="uk-margin-remove uk-modal-title">Manage Zone</h2>
            <p>{{ adminStore.isLoading }}</p>
            <button class="uk-modal-close-full uk-close-large uk-border-circle" type="button" uk-close></button>
        </div>
        <div class="uk-flex uk-modal-body uk-position-relative">
            
            <!-- Map Sidebar where zone info appears -->
            <div id="sidebar" class="uk-padding uk-width-2-5 uk-width-1-4@xl">

                <AgileButton type="button" class="uk-width-1-1" @click="saveZone" :disabled="!zone.allStepsCompleted" :title="[!zone.allStepsCompleted ? 'Complete all steps to save zone' : '']">Save Zone</AgileButton>
                <AgileButton v-if="zone.seq" class="uk-width-1-1 uk-button-link uk-text-danger uk-padding-small" @click="deleteZone">Delete Zone</AgileButton>

                <!-- <div uk-slider="center: true">
                    
                    <div class="uk-flex uk-flex-between uk-child-width-1-3">
                        <AgileButton @click="zone.prevStep" uk-slider-item="previous" :disabled="zone.currentStep == 0">Previous</AgileButton>
                        <AgileButton @click="zone.nextStep" uk-slider-item="next" :disabled="!zone.currentStepCompleted">Next</AgileButton>
                    </div>
                    <div class="uk-slider-items uk-child-width-5-6"> -->
                        <div class="zone-details uk-margin-bottom uk-margin-top uk-padding-small uk-border-rounded active-zone">

                            <h4 class="zone-header uk-margin-bottom">Step 1 <strong :class="{'uk-invisible': !zone.completed.stepOne}"><span class="uk-text-secondary" :uk-icon="'icon: check; ratio: 2;'"></span></strong></h4>
                            
                            <div class="uk-margin-bottom">
                                <label for="zone-name">Zone Name</label>
                                <input type="text" name="zone-name" id="zone-name" class="uk-input" v-model="zone.name">
                            </div>
                        </div>

                        <div class="zone-details uk-margin-bottom uk-margin-top uk-padding-small uk-border-rounded active-zone">

                            <h4 class="zone-header uk-margin-bottom">Step 2 <strong :class="{'uk-invisible': !zone.completed.stepTwo}"><span class="uk-text-secondary" :uk-icon="'icon: check; ratio: 2;'"></span></strong></h4>

                            <div class="uk-margin-bottom">
                                <div class="uk-flex uk-flex-between uk-margin-remove">
                                    <label class="uk-width-1-3 uk-margin-small-right">Unit Type</label>
                                    <label class="uk-width-1-3 uk-margin-small-right">Coverage</label>
                                    <label class="uk-width-1-6">Price/sq ft</label>
                                    <label class="uk-width-1-6"></label>
                                </div>
                                <div v-for="(price, pIndex) in zone.prices" class="uk-flex uk-flex-between uk-margin-small-bottom">
                                    <select class="uk-select uk-width-1-3 uk-margin-small-right" v-model="price.unit_type">
                                        <option value="">Select One</option>
                                        <option v-for="unit_type in adminStore.unit_types" :value="unit_type.list_seq" :disabled="unitTypeExhausted(unit_type.list_seq)">{{ unit_type.screen_option }}</option>
                                    </select>
                                    <select class="uk-select uk-width-1-3 uk-margin-small-right" v-model="price.coverage_type">
                                        <option value="">Select One</option>
                                        <option v-for="coverage_type in adminStore.coverage_types" :value="coverage_type.list_seq" :disabled="coverageTypeExhausted(price.unit_type, coverage_type.list_seq)">{{ coverage_type.screen_option }}</option>
                                    </select>
                                    <input type="number" class="uk-input uk-width-1-6" placeholder="Price" v-model="price.price" min="1">
                                    <button @click="() => zone.prices.splice(pIndex, 1)" class="uk-button uk-button-link uk-text-danger uk-width-1-6" type="button" :disabled="zone.prices.length < 2">
                                        <span v-if="zone.prices.length > 1" uk-icon="icon: minus-circle"></span>
                                    </button>
                                </div>
                                <button @click="zone.prices.push(usePrice({zone_seq: zone.seq}))" class="uk-button uk-button-link uk-display-block uk-margin-auto uk-padding-small uk-text-center">Add Unit Type</button>
                            </div>

                        </div>

                        <div class="zone-details uk-margin-bottom uk-margin-top uk-padding-small uk-border-rounded active-zone">

                            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-small-bottom">
                                <h4 class="zone-header uk-margin-remove">Step 3 <strong :class="{'uk-invisible': !zone.completed.stepThree}"><span class="uk-text-secondary" :uk-icon="'icon: check; ratio: 2;'"></span></strong></h4>
                                <AgileButton v-if="shapeSelector != null" type="button" class="uk-button-default uk-text-muted" @click="resetZoneSelections">Start Over</AgileButton>
                            </div>

                            <div v-if="!shapeSelector && !zone.counties.length && !zone.ContactKey && !zone.state" class="uk-margin-bottom">
                                <p class="uk-margin-remove">Zone Boundaries</p>
                                <AgileButton type="button" class="uk-width-1-1 uk-margin-small-bottom" @click="selectCountiesForZone">Select Counties</AgileButton>
                                <AgileButton type="button" class="uk-width-1-1 uk-margin-small-bottom" @click="selectDistanceForZone">Distance From Location</AgileButton>
                            </div>

                            <div v-else class="uk-margin-bottom">
                                <div v-if="shapeSelector == SHAPE_TYPE_COUNTIES || (!shapeSelector && !zone.ContactKey && zone.state != undefined)">
                                        <label for="zone-state">Select a state to choose counties</label>
                                        <AgileStateSelector name="zone-state" id="zone-state" class="uk-margin-small-bottom" v-model="zone.state" @change="(e) => showCountyBoundariesFor(e.target.value)" />

                                        <div class="uk-margin-bottom">
                                            <template v-if="!clickToSelectEnabled && !shapeDrawingEnabled">
                                                <p class="uk-margin-remove">Selection Tools</p>
                                                <AgileButton @click="() => enableShapeClickToSelect({ showPopup: true })" type="button" class="uk-width-1-1 uk-margin-small-bottom">Select Counties</AgileButton>
                                                <AgileButton @click="() => drawShape('polygon')" type="button" class="uk-width-1-1 uk-margin-small-bottom">Use Lasso Tool</AgileButton>
                                                <AgileButton @click="() => drawShape('circle')" type="button" class="uk-width-1-1 uk-margin-small-bottom">Use Circle Tool</AgileButton>
                                            </template>

                                            <AgileButton v-if="clickToSelectEnabled" @click="disableShapeClickToSelect" type="button" class="uk-width-1-1 uk-margin-small-bottom">Finished Selecting Counties</AgileButton>

                                            <AgileButton v-if="shapeDrawingEnabled" @click="finishDrawingShape" type="button" class="uk-width-1-1 uk-margin-small-bottom">Finished Drawing</AgileButton>
                                        </div>
                                </div>

                                <div v-else-if="shapeSelector == SHAPE_TYPE_DISTANCE || (!shapeSelector && !zone.state && zone.ContactKey != undefined)">
                                    <div class="uk-flex uk-flex-around uk-flex-middle">
                                        <label for="shape-location" class="uk-width-1-2">Location</label>
                                        <label for="shape-radius" class="uk-width-1-2 uk-margin-small-left">Radius in miles</label>
                                    </div>
                                    <div class="uk-flex uk-flex-around uk-flex-middle">
                                        <select v-model="zone.ContactKey" @change="convertLocationToCenterPoint" class="uk-margin-small-bottom uk-select uk-width-1-2" id="shape-location">
                                            <option value="">Select a Location</option>
                                            <option v-for="location in adminStore.locations" :value="location.ContactKey"><strong>{{ location.name }}</strong></option>
                                        </select>
                                        <input @keyup.enter="generateShape" v-model="zone.radius" type="number" name="shape-radius" id="shape-radius" class="uk-input uk-margin-small-bottom uk-margin-small-left uk-width-1-2" placeholder="Enter radius in miles">
                                    </div>

                                    <AgileButton
                                        @click="generateShape"
                                        type="button"
                                        class="uk-width-1-1 uk-margin-small-bottom uk-button-secondary"
                                        :disabled="!zone.radius || !zone.ContactKey">Generate Zone Boundary</AgileButton>
                                </div>

                                <p v-if="zone.counties.length > 0" class="uk-margin-remove">{{ zone.counties.length }} Selected Counties</p>

                                <p v-if="zone.counties.length == 0 && clickToSelectEnabled" class="uk-text-center uk-text-italic">Click on a county to add/remove</p>

                                <div v-for="county in zone.counties" class="uk-flex uk-flex-middle uk-margin-small-bottom">
                                    <input type="text" class="uk-input uk-width-5-6" :value="`${county.county}, ${county.state}`" readonly />
                                    <button @click="() => removeCounty(county)" type="button" class="uk-button uk-button-link uk-width-1-6 uk-text-center uk-text-danger"><span uk-icon="icon:trash"></span></button>
                                </div>

                            </div>

                        </div>

                        <AgileButton type="button" class="uk-width-1-1" @click="saveZone" :disabled="!zone.allStepsCompleted" :title="[!zone.allStepsCompleted ? 'Complete all steps to save zone' : '']">Save Zone</AgileButton>
                        <AgileButton v-if="zone.seq" class="uk-width-1-1 uk-button-link uk-text-danger uk-padding-small" @click="deleteZone">Delete Zone</AgileButton>
                    <!-- </div>
                </div> -->

            </div>


            <!-- This is the element for the map -->
            <div class="uk-flex uk-flex-center uk-flex-middle uk-width-3-5 uk-width-3-4@xl" id="location-zone-map"></div>

            <!-- Map overlay to let the user know data is being loaded -->
            <div v-if="loadingMap" class="uk-flex uk-flex-center uk-flex-middle uk-width-2-3 uk-width-3-4@xl uk-position-right" id="location-zone-map-overlay">
                <div>
                    <h3 class="uk-text-muted uk-text-center uk-font-bold">Loading map data...</h3>
                    <div class="uk-margin-bottom uk-position-relative uk-flex uk-flex-middle uk-flex-center">
                        <span class="cog cog-white" :uk-icon="'icon: cog; ratio: 4;'"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>