import { computed, ref, toValue, toRefs, reactive } from 'vue';
import { useCounties } from '~/src/composables/counties';
// import { usePrices } from '~/src/composables/prices';
import { usePrice } from '~/src/composables/price';
import { useAdminStore } from '~/src/stores/admin.store';

export const SHAPE_TYPE_STATES = "states";
export const SHAPE_TYPE_COUNTIES = "counties";
export const SHAPE_TYPE_DISTANCE = "distance";

export const useZone = (zoneProps = {}) => {
    /** Properties */
    const seq = ref(toValue(zoneProps.seq));
    const name = ref(toValue(zoneProps.name) ?? '');
    const map_data = ref(toValue(zoneProps.map_data) ?? []);
    const counties = ref(toValue(zoneProps.counties) ?? []);
    const prices = ref(toValue(zoneProps.prices) ?? [usePrice()]);
    const ContactKey = ref(toValue(zoneProps.ContactKey));
    const state = ref(toValue(zoneProps.state) ?? '');
    const radius = ref(toValue(zoneProps.radius) ?? 0);


    /** Getters */
    const pricesAreSet = computed(() => prices.value.every((price) => price.unit_type != "" && price.coverage_type != "" && price.price > 0));
    const hasCounties = computed(() => counties.value.length > 0);
    const hasName = computed(() => name.value != '');
    const hasShapes = computed(() => map_data.value.length > 0);

    const completed = reactive({
        stepOne: hasName,
        stepTwo: pricesAreSet,
        stepThree: hasCounties
    });

    const currentStep = ref(0);
    const currentStepKey = computed(() => Object.keys(completed)[currentStep.value]);
    const currentStepCompleted = computed(() => completed[currentStepKey.value] === true);

    const allStepsCompleted = computed(() => Object.values(completed).every((step) => step == true));

    /** Setters */
    const add = (shape) => {
        console.info("Adding shape to zone");
        console.debug(shape);
        if (typeof shape.addProperty == 'function') {
            shape.addProperty('active', 'true');
        }

        console.info("Adding to map data");
        map_data.value.push(shape);

        if (!Object.hasOwn(shape, 'data') && !Object.hasOwn(shape, 'properties')) {
            return;
        }

        let countyName, countyState;

        if (Object.hasOwn(shape, 'data')) {
            if (shape.data.properties.shape_type != "zone_county") return;

            countyName = shape.data.properties.name;
            countyState = shape.data.properties.STATE_NAME.value;

            let county = useCounties().newCounty();

            county.county = countyName;
            county.zone_seq = seq.value;
            county.state = countyState;

            console.info("Adding to county list");
            counties.value.push(county);
        }
    };

    const clearCounties = () => {
        console.info('Clearing zone counties list');
        counties.value = [];
    }

    const clearMapData = () => {
        console.info('Clearing zone map data');
        map_data.value = [];
    }

    const contains = (shape) => {
        if (map_data.value == undefined) return false;

        return map_data.value.some((shapeInZone) => {
            return shapeInZone.data.id == shape.data.id;
        });
    };

    const data = () => ({
        ContactKey: toValue(ContactKey),
        counties: toValue(counties),
        map_data: JSON.stringify(map_data.value.map((map_shape) => ({ data: map_shape.data }))),
        name: toValue(name),
        prices: toValue(prices),
        seq: toValue(seq),
        state: toValue(state),
        radius: toValue(radius),
        vend_no: useAdminStore().installerId
    });

    const nextStep = () => currentStep.value++;

    const prevStep = () => currentStep.value--;

    const removeCounty = (removedCounty) => {
        console.info("Removing county from zone");
        console.debug(removedCounty);

        // Woof this is ugly... might rethink this one when it's not first thing Monday morning... 🙃
        const countyIndex = counties.value.findIndex((county) => county.county == removedCounty.county && county.state == removedCounty.state);

        if (countyIndex >= 0 && countyIndex < counties.value.length) {
            counties.value.splice(countyIndex, 1);
        }
    };

    const removeMapShape = (removedShape) => {
        console.info("Removing county shape from zone");
        console.debug(removedShape);
        if (typeof removedShape.addProperty == 'function') {
            removedShape.addProperty('active', 'false');
        }
        
        console.info("Removing county shape from map data");
        map_data.value.splice(map_data.value.findIndex((shape) => shape.data.id == removedShape.data.id), 1);

        removeCounty({
            county: removedShape.data.properties.NAME.value,
            state: removedShape.data.properties.STATE_NAME.value
        });
    };

    const reset = () => {
        resetSelections();
        seq.value = undefined;
        name.value = undefined;
        prices.value = [usePrice()];
    };

    const resetSelections = () => {
        clearCounties();
        clearMapData();
        ContactKey.value = null;
        state.value = '';
        radius.value = 0;
    }

    const toggle = (toggledShape) => {
        if (contains(toggledShape)) {
            removeMapShape(toggledShape);
        } else {
            add(toggledShape);
        }
    };

    return {
        data,
        seq,
        name,
        map_data,
        counties,
        prices,
        ContactKey,
        state,
        radius,
        hasCounties,
        hasShapes,
        completed,
        currentStep,
        currentStepKey,
        currentStepCompleted,
        allStepsCompleted,
        nextStep,
        prevStep,
        add,
        clearCounties,
        clearMapData,
        removeCounty,
        removeMapShape,
        reset,
        resetSelections,
        toggle
    };
};