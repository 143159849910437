import { get, keys } from 'lodash';
import {defineStore} from 'pinia';
import { ref } from 'vue';
import { router } from '../router/router';
import { useAuthStore } from './auth.store';

const errorPrefix = "Encountered Error: ";
const franchise = process.env.Franchise;
const statusCodeLookup = {
    // could use this to change the text to whatever we want
    200: "OK",
    500: "Internal Server Error",
    504: "We seem to be having trouble on our end. Please refresh the page and try again.",
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
}

// List of status codes that should log the user out
const logoutCodes = [401, 403, 404, 405, 504, 500];

const lookupCode = (code) => {
    let codeString = statusCodeLookup[code];
    if (!codeString) {
        codeString = "Unknown Error";
    }
    return errorPrefix + codeString;
}

export const useErrorCheckStore = defineStore({
    id: 'useErrorCheckStore',
    state: () => ({
        statusCode: 0,
        showStatusCode: false,
        statusMessage: 'test message',
        errorList: [],
        successList: [],
    }),
    getters: {
        getCurrentStatusCode: (state) => {
           return state.statusCode;
        },
        getCurrentStatusMessage: (state) => {
            return state.statusMessage;
        },
        getErrorList: (state) => {
            return state.errorList;
        },
        getSuccessList: (state) => {
            return state.successList;
        },
        getShowErrorCode: (state) => {
            return state.showErrorCode;
        }
        
    },
    actions: {
        checkStatus(response, hideError) {
            // console.log("errorCheck store checkStatus", hideError);
            if (response.status == 200) {
                return true;
            } 
            else {
                for (let i = 0; i < logoutCodes.length; i++) {
                    if (logoutCodes[i] == response.status) {
                        localStorage.setItem('forceLogoutError', 'Disconnected from server. Please log in again.');
                        this.errorList.push("Disconnected from server. Please log in again.");
                        localStorage.removeItem(franchise + 'User');
                        localStorage.removeItem(franchise + 'JwtToken');
                        router.push('/');
                    }
                }
                this.statusCode = response.status;
                if (hideError != true)  {
                    // don't show status more than once
                    if (response.statusText && !this.errorList.includes(response.statusText)) {
                        this.errorList.push(response.statusText); 
                    } else {
                        if(!this.errorList.includes("Unknown Error")){
                            this.errorList.push("Unknown Error");
                        }
                    }
                    
                    // Use this version to use custom error messages
                    // this.errorList.push(lookupCode(response.status));
                }
                return false;
            }
        },
        checkStatusJSON(response, showSuccess, showError) {
            if (showSuccess != true){
                showSuccess = false;
            }
            if (showError != true ) {
                showError = false;
            }
            // this assumes the response is in AXMessage format from the backend
            // console.log("Error Check JSON: ", response);
            if (response.Status == 'ok'){
                if (showSuccess == true) {
                    if (!this.successList.includes(response.Message)) {
                        this.successList.push(response.Message);
                    }
                }
                return true;

            } else {
                if (showError == true) {
                    if (!this.errorList.includes(response.Message)) {
                        this.errorList.push(response.Message? response.Message : "Unknown Error");
                    }
                } 
                return false;
            }
        },
        validateSignIn(username, password){
            if (!username || !password) {
                // this.statusCode = 0;
                this.errorList.push("Username and password are required.");
                // this.show = true;
                return false;
            }
            return true;
        },
        validateEmailPasswordReset(email){
            if (!email) {
                // this.statusCode = 0;
                this.errorList.push("Email is required.");
                // this.show = true;
                return false;
            }
            return true;
        },
        validatePassword(newPassword, confirmPassword){
            if (!newPassword || !confirmPassword) {
                // this.statusCode = 0;
                this.errorList.push("Passwords are required.");
                // this.show = true;
                return false;
            }
            if (newPassword != confirmPassword 
                || newPassword == "" && confirmPassword == ""
                || newPassword == null && confirmPassword == null
                || newPassword == undefined && confirmPassword == undefined){
                
                this.errorList.push("Passwords are blank or do not match.");
                this.statusCode = 0;
                this.show = true;
                return false;
        
            } else {
                return true;
            }
        },
        hasValue(item, itemKey){
            if (!item) {
                this.errorList.push("Property has no value.", itemKey);
                return false;
            }
            return true;
        },
        clearErrorList(){
            this.errorList = [];
        },
        clearSuccessList(){
            this.successList = [];
        },
        hide(){
            this.errorList = [];
            this.successList = [];
        },
        async pushError(message){
            this.errorList.push(message);
        }
    }
});