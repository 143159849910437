﻿<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
// import { authHeader } from "../helpers/auth";
import {authHeader} from '../router/authheader';
import { useAuthStore } from '../stores/auth.store';
import { adminUsers } from '../constants/adminUsers.js';

const baseUrl = process.env.APIURL + "/users";
const {user} = useAuthStore();
// const adminUser = user;
// const franchise = process.env.Franchise;

let adminAllowed = ref(false);

let userList = ref([]);
const myRouter = useRouter();
onBeforeMount(() => {
  // console.log("Check for access");
  adminAllowed.value = adminUsers.data.includes(user.Username);
  if (!adminAllowed.value) {
    // console.log("adminAllowed", adminAllowed.value);
    myRouter.push('projects');
  }
});

onMounted(() => {
  if (adminAllowed.value === true) {
    // console.log("TheUserList is mounted");
    fetchAllUsers();
  }
});

async function fetchAllUsers() {
  // console.log("fetchAllUsers");
  const url = baseUrl + "/GetAll";
  let response = await fetch(url, {
    method: "GET",
    headers: authHeader(url),
  });
  let userResponse = await response.json();
  if (userResponse.Status === "ok") {
    userList.value = userResponse.Data;
    // console.log("userList: ", userResponse);
  }
  if (userResponse.Status === "exception") {
    // console.log("Could not retrieve userResponse:", userResponse.Message);
  }
}
</script>

<template>
  <div class="uk-container uk-container-large">
    <div>
      <h3>Users</h3>
      <table class="uk-table uk-table-middle">
        <thead>
        <th class="uk-table-shrink"><span class="uk-h4">ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Customer ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Username</span></th>
        <!--                    <th class="uk-table-shrink"><span class="uk-h4">Customer Name</span></th>-->
        <th class="uk-table-expand"><span class="uk-h4">RefreshTokens</span></th>
        </thead>
        <tbody>
        <tr v-for="u in userList">
          <td>{{ u.Id }}</td>
          <td>{{ u.CustomerId }}</td>
          <td>{{ u.Username }}</td>
          <!--                        <td>{{ u.CustomerName }}</td>-->
          <td class="uk-text-truncate">
            <div class="uk-panel uk-panel-scrollable">
              <template v-for="t in u.refreshTokens">
                <div class="uk-background-default uk-padding-small uk-margin-small">
                  <!--                  <div class="uk-text-truncate"><strong>Token:</strong> {{ (t.token).slice(0, 50) }}...</div>-->
                  <div class="uk-text-truncate"><strong>Token:</strong> {{ t.token }}...</div>
                  <div><strong>Created:</strong> {{ t.created }}</div>
                  <div><strong>Active:</strong> {{ t.isActive }}</div>
                  <div><strong>Revoked:</strong> {{ t.isRevoked }}</div>
                  <div><strong>Revoked Reason:</strong> {{ t.reasonRevoked }}</div>
                  <div><strong>Expires:</strong> {{ t.expires }}</div>
                  <div><strong>Expired:</strong> {{ t.isExpired }}</div>
                </div>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div> <!--end container-->
</template>
