<script setup>
import {computed, onBeforeMount, ref, watch} from 'vue';
import uikit from 'uikit';
import {useAQStore} from '../stores/aq.store.js';
import InstallationCard from './InstallationCard.vue';

const props = defineProps({
  modelValue: {},
  ID: {
    type: String,
  },
  Headers: {
    type: Array,
    value: [{
      type: Object,
      value: {
        key: {
          type: String
        },
        ratio: {
          type: String
        },
        column: {
          type: Number
        },
        display: {
          type: String
        }
      }
    }]
  },
  MobileHeaders: {
    type: Array,
    value: [{
      type: Object,
      value: {
        key: {
          type: String
        },
        ratio: {
          type: String
        },
        column: {
          type: Number
        },
      }
    }]
  },
  ToggleKey: {
    type: String,
  },
  FetchKey: {
    type: String,
  },
  ClosedKey: {
    type: Boolean,
  },
  EndPoint: {
    type: String,
  },
  Data: {
    type: Array,
  },
  DataType: {
    type: String,
  },
  Close: {
    type: Function
  }
})

const sorting = ref(false);
const sortedData = ref([]);
const sortedHeaders = ref([]);
const sortedMobileHeaders = ref([]);
const currentSelected = ref();
const activeHeader = ref();
const order = ref('');
const filter = uikit.filter('[uk-filter]');
const screenWidth = ref();
const showInvoiceTool = ref(false);
const currentAQ = ref({});
// const filtered = [];

onBeforeMount(() => {
  // console.log("Props data: ", props.Data);
  screenWidth.value = window.innerWidth;
  reorderHeaders();
  // hideClosed();
  // sortDescendingBy("Status")
})

const checkSearchMatch = (item) => {
  // console.log("Checking search for match...");
  let match = false;
  Object.keys(item).forEach((key) => {
    if (String(item[key]).toLocaleLowerCase().includes(props.modelValue.toLocaleLowerCase())) {
      // console.log("Match found");
      match = true;
    }
  })
  return match;
}


const getID = (item) => {
  return String(item);
}

const fetchAQ = (aq) => {
  // console.log("Fetching AQ: ", aq);
}

const setSelected = (item, index) => {
  // console.log("Setting selected: item", item);
  // console.log("Setting selected: index", index);
  // console.log("Current selected: ", item);
  // if (props.Data[item]['PO']) {
  if (item.PO) {
    console.log("setting PO");
    useAQStore().setCurrentPO(item.PO);
  }
  // if (props.Data[item]['AQ']) {
  if (item.AQ) {
    console.log("settingAQ");
    useAQStore().setCurrentAQ(item.AQ);
  }

  useAQStore().setCurrentProject(item.Project);
  // set current AQ Object
  let tempAQObject = item;
  // selected is for styling.
  currentSelected.value = index;
  // console.log("setSelected, currentSelected.value", currentSelected.value);
  isSelected(index);
  currentAQ.value = tempAQObject;
}

const sortAscendingBy = (key) => {
  let sortedData = props.Data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  })
  return sortedData;
}

function filterClosedByStatus(data = [], closedKey = true) {
  if (closedKey === false) {
    return data.filter(d => d.Status !== 'Closed');
  }
  return data;
}

const filtered = computed(() => {
  // console.log('hideClosed filtered', props.ClosedKey);
  return filterClosedByStatus(props.Data, props.ClosedKey);
});

const sortDescendingBy = (key) => {
  let sortedData = props.Data.sort((a, b) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  })
  return sortedData;
}

const isSelected = (index) => {
  return currentSelected === index;
}

watch(currentSelected, (newVal, oldVal) => {
  isSelected(newVal);
})

const reorderHeaders = () => {
  let headerSort = props.Headers.sort((a, b) => {
    if (a.column < b.column) {
      return -1;
    }
    if (a.column > b.column) {
      return 1;
    }
    return 0;
  })
  let mobileHeaders = props.MobileHeaders.sort((a, b) => {
    if (a.column < b.column) {
      return -1;
    }
    if (a.column > b.column) {
      return 1;
    }
    return 0;
  })
  // console.log("Sorted Headers: ", headerSort);
  sortedHeaders.value = headerSort;
  // console.log("Sorted headers after value set: ", sortedHeaders);
  sortedMobileHeaders.value = mobileHeaders;
}

const checkColumnHeaders = (header) => {

  let generatedClass = '';

  if (header.ratio > 1) {
    generatedClass = 'uk-text-center';
  } else {
    generatedClass = 'uk-text-left';
  }

  return generatedClass;
}

// DEPRECATED appears to be unused
/*const checkColumnKeys = (index) => {
  if (index === 0) {
    // console.log("returning left");
    return 'uk-text-left';
  } else if (index === props.Headers.length - 1) {
    return 'uk-text-right';
  } else {
    return 'uk-text-center';
  }
}*/

const checkWidth = () => {
  // console.log("Screen width: ", screenWidth.value);
  return screenWidth.value > 768;
}

</script>

<template>

  <div class="uk-container uk-margin-medium-top">
    <div
        class="project-list-header uk-visible@m uk-flex uk-flex-between uk-light uk-background-secondary uk-margin-bottom uk-padding-small">
      <div v-for="(header, index) in sortedHeaders" :class="'uk-text-center uk-width-'+ header.ratio + '-4 '">
                    <span class=" uk-margin-remove uk-text-bold uk-text-uppercase uk-width-1-1">
                        {{ header.display ? header.display : header.key }}
                    </span>
      </div>
    </div>
    <div
        class="project-list-header uk-hidden@m uk-flex uk-flex-between uk-light uk-background-secondary uk-margin-bottom uk-padding-small">
      <div v-for="(header, index) in sortedMobileHeaders" :class="'uk-text-center uk-width-'+ header.ratio + '-4 '">
                <span class=" uk-margin-remove uk-text-bold uk-text-uppercase uk-width-1-1">
                    {{ header.display ? header.display : header.key }}
                </span>
      </div>
    </div>
    <ul v-if="props.Data" class="js-filter uk-padding-remove">
      <li v-for="(item, index) in filtered" class="uk-list uk-card uk-flex uk-flex-column uk-margin-small-bottom"
          :class="{'selected' : currentSelected == item}" :hidden="!checkSearchMatch(item)">
        <InstallationCard
            @click="setSelected(item, index)"
            :ID="props.ID + index"
            :class="{'selected' : currentSelected === index}"
            :Selected="currentSelected === index"
            v-model="props.modelValue"
            :Data="{...item}"
            :EndPoint="props.EndPoint"
            :Headers="sortedHeaders"
            :MobileHeaders="sortedMobileHeaders"
            :ToggleKey="props.ToggleKey"
            :FetchKey="props.FetchKey"
            :DataType="props.DataType"
        />
      </li>
    </ul>
  </div>
</template>