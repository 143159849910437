import { useAuthStore } from '~/src/stores/auth.store';
import { authHeader } from '~/src/router/authheader.js';

export const useAdminApi = () => {
    const authStore = useAuthStore();

    const method = "POST";
    const input = {
        "vend_no": authStore.user.InstallerID
    };

    const apiUrl = (endpoint) => `${process.env.APIURL}/Admin/${endpoint}`;
    const apiBody = (params) => JSON.stringify({ ...input, ...params });
    const apiParams = (url, extraParams) => ({
        method,
        headers: authHeader(url),
        body: apiBody(extraParams)
    });

    const makeApiRequest = async (url, extraReqParams = {}) => {
        const apiResponse = await fetch(url, apiParams(url, extraReqParams));
        const apiResponseJson = await apiResponse.json();

        return apiResponseJson;
    }

    return {
        apiUrl,
        makeApiRequest
    };
}

