import {defineStore} from 'pinia';
import {router} from '../router/router';
import {_} from 'lodash';
const baseUrl = process.env.APIURL + "/Users";
const franchise = process.env.Franchise;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in.
        // in Pinia state is always initialized here. You could code values, get values from a fetch, or do this.
        user: JSON.parse(localStorage.getItem(franchise + 'User')),
        JwtToken: localStorage.getItem(franchise + "JwtToken"),
        returnUrl: null,
        userInstallerName: null,
        // explicitLogout: false,
    }),
    getters: {
        // I think refresh jwt goes here.
        refreshJwtToken() {
        }
    },
    actions: {
        setUser(user) {
            // console.log("authStore setUser: ", user);
            // update pinia state
            this.user = user;
            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem(franchise + 'User', JSON.stringify(user));
        },
        getUser(){
            return this.user;
        },
        getUserInstallerName(){
            return this.userInstallerName;
        },
        setUserInstallerName(name){
            this.userInstallerName = name;
        },
        // setExplicitLogout(status) {
        //     this.explicitLogout = status;
        // },
        // getExplicitLogout() {
        //     return this.explicitLogout;
        // },
        setJwtToken(token) {
            // console.log("authStore setJwtToken: ", token);
            // update pinia state
            this.JwtToken = token;
            localStorage.setItem(franchise + "JwtToken", token);
            // Wait delay time and get a new token
            // const _refresh = this.refreshToken;
        },
        clearStore() {
            this.$reset();
        },
        async logout() {
            // true is no user available. false is valid user.
            const userStatus = _.isNil(this.user);
            // console.log("logging out a valid user");
            if (!userStatus) {
                let url = baseUrl + '/logout';
                const call = {
                    "username": this.user.Username,
                };
                let _user = this.user;
                fetch(url, {
                    method: 'POST',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.user.JwtToken}`,
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
                    },
                    body: JSON.stringify(call)
                }).then(response => {
                    return response.json();
                }).then(function (jsondata) {
                    // if ok or failed, clean the user from stores
                    if (jsondata.Status === "ok" || jsondata.Status === "failed") {
                        _user = null;
                        localStorage.removeItem(franchise + 'User');
                        localStorage.removeItem(franchise + 'JwtToken');
                        localStorage.removeItem('currentAQ');
                        localStorage.removeItem('currentPO');
                        localStorage.removeItem('currentObjectID');
                        localStorage.removeItem('currentProject');
                        localStorage.removeItem('LocID');
                        localStorage.removeItem('AQObject');

                        // console.log("User logged out..");
                        router.push({name: 'home'});
                        // return;
                    }
                    // if an exception, just go home, but leave logged in locally??.
                    if (jsondata.Status === "exception") {
                        // console.log("user logout exception");
                        router.push({name: 'home'});
                        // return;
                    }
                    // Handle a not authorized or 404. Clean the user and let them login again
                    if (jsondata.message === "User not found" || jsondata.Status === "unauthorized") {
                        // console.log("user logout not found, unauthorized");
                        _user = null;
                        localStorage.removeItem(franchise + 'User');
                        localStorage.removeItem(franchise + 'JwtToken');
                        router.push({name: 'home'});
                    }
                    // router.push({name: 'home'});
                    window.location.reload();
                });
            } else {
                // There is no user in the authStore. Clear the localStorage
                localStorage.removeItem(franchise + 'User');
                localStorage.removeItem(franchise + 'JwtToken');
                // window.location.reload();
                router.push({name: 'home'});
            }
        },
        async refreshToken() {
            // console.log("user token: ", this.user.RefreshToken)
            if (this.user.RefreshToken){

                // console.log("authStore refreshToken running");
                let returnValue = false;
                var url = baseUrl + "/refresh-token";
                const call = {
                    Token: this.user.RefreshToken
                }
                if (this.user) {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${this.JwtToken}`,
                            "Access-Control-Allow-Credentials": true,
                            "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
                        },
                        body: JSON.stringify(call)
                    });
                    const newTokens = await response.json();
                    // console.log("got new tokens",newTokens);
                    const userWithToken = newTokens.Data;
                    if (newTokens.Status === "exception") {
                        localStorage.removeItem(franchise + 'User');
                        localStorage.removeItem(franchise + 'JwtToken');
                        returnValue = false;
                    }
                    if (newTokens.Status === "ok") {
                        // console.log("new token setting");
                        const token = userWithToken.JwtToken;
                        if (token.length > 0) {
                            this.setJwtToken(token);
                            delete userWithToken.JwtToken;
                            const user = userWithToken;
                            this.setUser(user);
                            returnValue = true;
                        } else {
                            returnValue = false;
                        }
                    }
                }
                return returnValue;
            }
            return false;
        },
        error504(){
            localStorage.removeItem(franchise + 'User');
            localStorage.removeItem(franchise + 'JwtToken');
            this.user = null;
            router.push({name: 'home'});
        }
    }
});