import {defineStore} from 'pinia';
import { nextTick } from 'vue';

export const useAQStore = defineStore({
    id: 'useAQStore',
    state: () => ({
        currentAQ: localStorage.getItem('currentAQ') || '',
        currentPO: localStorage.getItem('currentPO'),
        currentProject: localStorage.getItem('currentProject') || '',
        currentObjectID: localStorage.getItem('currentObjectID') || '',
        currentAQObject: localStorage.getItem('AQObject') || '',
        // currentAQObject: {
        //     AQ: '',
        //     PO: '',
        //     Project: '',
        //     Date: '',
        //     Coordinator: '',
        //     Status: '',
        // }
    }),
    getters: {
        getCurrentAQ: (state) => {
            nextTick(() => {
                if (localStorage.getItem('currentAQ') != null) {
                    state.currentAQ = localStorage.getItem('currentAQ');
                    return state.currentAQ;
                }
                return state.currentAQ;
            });
        },
        getCurrentPO: (state) => {
            nextTick(() => {
                if (localStorage.getItem('currentPO') != null) {
                    state.currentPO = localStorage.getItem('currentPO');
                    return state.currentPO;
                }
                return state.currentPO;
            });
            return state.currentPO;
        },
        getCurrentObjectID: (state) => {
            nextTick(() => {
                if (localStorage.getItem('currentObjectID') != null) {
                    state.currentObjectID = localStorage.getItem('currentObjectID');
                    return state.currentObjectID;
                }
                return state.currentObjectID;
            });
            return state.currentObjectID;
        },
        getCurrentProject: (state) => {
            nextTick(() => {
                if (localStorage.getItem('currentProject') != null) {
                    state.currentProject = localStorage.getItem('currentProject');
                    return state.currentProject;
                }
                return state.currentProject;
            });
            return state.currentProject;
        },
        getCurrentAQObject: (state) => {
            return state.currentAQObject;
        }
    },
    actions: {
        setCurrentAQ(selection) {
            localStorage.setItem('currentAQ', selection);
            this.currentAQ = selection;
        },
        setCurrentPO(selection) {
            localStorage.setItem('currentPO', selection);
            this.currentPO = selection;
        },
        setCurrentObjectID(selection) {
            localStorage.setItem('currentObjectID', selection);
            this.currentObjectID = selection;
        },
        setCurrentProject(selection) {
            localStorage.setItem('currentProject', selection);
            this.currentProject = selection;
        },
        setCurrentAQObject(selection) {
            localStorage.setItem('AQObject', selection);
            this.currentAQObject = selection;
        },
        clearStore() {
            this.$reset();
        },
    }
});