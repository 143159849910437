<script setup>
import { onMounted } from 'vue';
import { useDocumentsApi } from '~/src/api/documents';
import { useInstallerApi } from '~/src/api/installer';
import { useLocationsApi } from '~/src/api/locations';
import { useQualificationsApi } from '~/src/api/qualifications';
import { useWebInstallersApi } from '~/src/api/webInstallers';
import { useZonesApi } from '~/src/api/zones';

const documents = useDocumentsApi();
const locations = useLocationsApi();
const installer = useInstallerApi();
const qualifications = useQualificationsApi();
const webInstallers = useWebInstallersApi();
const zones = useZonesApi();

onMounted(() => {
    documents.fetch();
    installer.fetch();
    locations.fetch();
    qualifications.fetch();
    webInstallers.fetch();
    zones.fetch();
});
</script>

<template>
    <div class="uk-container">
        <h1 class="uk-margin-medium-bottom">Installer Administration</h1>
        <!-- <ul class="uk-visible@m uk-margin-medium-bottom" uk-tab>
            <li :class="{'uk-active': $route.name == 'business-details'}"><router-link :to="{ name: 'business-details' }">Business Details</router-link></li>
            <li :class="{'uk-active': $route.name == 'manage-locations'}"><router-link :to="{ name: 'manage-locations' }">Manage Locations</router-link></li>
            <li :class="{'uk-active': $route.name == 'manage-users'}"><router-link :to="{ name: 'manage-users' }">Manage Users</router-link></li>
            <li :class="{'uk-active': $route.name == 'manage-zones'}"><router-link :to="{ name: 'manage-zones' }">Manage Zone Pricing</router-link></li>
        </ul>
        <div class="uk-hidden@m">
            <button class="uk-button uk-button-default uk-display-block uk-margin-auto uk-text-muted uk-width-4-5">Admin Menu</button>
            <div uk-dropdown="delay-hide: 0; stretch: x">
                <ul class="uk-nav uk-dropdown-nav">
                    <li :class="{'uk-active': $route.name == 'business-details'}"><router-link :to="{ name: 'business-details' }">Business Details</router-link></li>
                    <li :class="{'uk-active': $route.name == 'manage-locations'}"><router-link :to="{ name: 'manage-locations' }">Manage Locations</router-link></li>
                    <li :class="{'uk-active': $route.name == 'manage-users'}"><router-link :to="{ name: 'manage-users' }">Manage Users</router-link></li>
                    <li :class="{'uk-active': $route.name == 'manage-zones'}"><router-link :to="{ name: 'manage-zones' }">Manage Zone Pricing</router-link></li>
                </ul>
            </div>
        </div> -->
        <router-view />
    </div>
</template>