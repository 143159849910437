<!---------------------------------------------------------------------

    When creating this component, you must pass in a v-model="refName"
    The refName's value becomes bound to the input value of 
    this component.  Example below:

    <LowenInputStacked v-model="location" :id="'refName'" :lable="'Ref Name'" type="text" />

 ---------------------------------------------------------------------->


<script setup>
import { computed } from 'vue';


let props = defineProps({
    modelValue: {
    },
    id: {
        type: String
    },
    label: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'text'
    },
    autocomplete: {
        type: String,
        default: 'false'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    step: {
        type: String,
        default: ''
    },
    min: {
        type: String,
        default: ''
    },
    max: {
        type: String,
        default: ''
    },
    required: {
        type: Boolean,
        default: false
    },
})

let showErrors = computed(() => {
    let showErrors = false;
    if (props.required && props.modelValue.length === 0) {
        showErrors = true;
    }
    return showErrors;
})

defineEmits(['update:modelValue'])
</script>

<template>
    <div class="lowen-input-stacked-container uk-flex uk-flex-column uk-margin-remove">
        <label v-if="label" :for="props.id">{{ props.label }}</label>
        <input 
            class="uk-input uk-margin-small-bottom" 
            :class="{'uk-form-danger': showErrors}"
            :id="props.id" 
            :type="props.type" 
            :autocomplete="props.autocomplete"
            :value="props.modelValue"
            :disabled="props.disabled"
            :readonly="props.readonly"
            :placeholder="props.placeholder"
            :step="props.step"
            :min="props.min"
            :max="props.max"
            @input="$emit('update:modelValue', $event.target.value)"
            >
    </div>
</template>