import { useAdminApi } from '~/src/api/admin';
import { useAdminStore } from '~/src/stores/admin.store';

export const useZonesApi = () => {
    const adminStore = useAdminStore();
    const { apiUrl, makeApiRequest } = useAdminApi();

    const destroy = async (zoneSeq) => {
        adminStore.toggleLoading('zoneDelete');

        const url = apiUrl('Zones/Destroy');
        const responseJson = await makeApiRequest(url, { zone: zoneSeq });

        adminStore.toggleLoading('zoneDelete');

        return responseJson;
    };

    const fetch = async () => {
        adminStore.toggleLoading('zoneFetch');

        const url = apiUrl('Zones/Fetch');
        const responseJson = await makeApiRequest(url);

        adminStore.toggleLoading('zoneFetch');

        adminStore.set('zones', responseJson.Data.zones);
        adminStore.set('unit_types', responseJson.Data.group_types);
        adminStore.set('coverage_types', responseJson.Data.coverage_types);

        return responseJson;
    };

    const save = async (zoneData) => {
        adminStore.toggleLoading('zoneSave');

        const url = apiUrl('Zones/Save');
        const responseJson = await makeApiRequest(url, {zone: zoneData});

        adminStore.toggleLoading('zoneSave');

        return responseJson;
    };

    return {
        destroy,
        fetch,
        save
    };
};