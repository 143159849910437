<script setup>
import { useDocumentsStore } from '~/src/stores/documents.store';
import DocumentSignature from '~/src/components/DocumentSignature.vue';
import DocumentUpload from '~/src/components/DocumentUpload.vue';

const docStore = useDocumentsStore();
</script>

<template>
    <div id="tax-insurance">
        <h2 class="uk-margin-remove">Tax &amp; Insurance Management</h2>
        <table class="uk-table uk-table-divider uk-table-justify uk-table-middle">
            <thead>
                <tr>
                    <th class="uk-width-1-3 uk-text-center">Document</th>
                    <th class="uk-width-1-3 uk-text-center">Status</th>
                    <th class="uk-width-1-3 uk-text-center"></th>
                </tr>
            </thead>
            <tbody>
                <DocumentSignature :document="docStore.installerAgreement" template="InstallerPolicy">Installer Agreement</DocumentSignature>
                <DocumentUpload :document="docStore.insuranceCertificate" :expirable="true">Certificate of Insurance</DocumentUpload>
            </tbody>
        </table>
    </div>
</template>