<!--
    Changing the button with props. Place the props in the component tag like this:
    Icons:  UIkit icons ->  icon="home"
    Icon Button: ->  :iconButton="true"
    Tool Tip: ->  toolTip="Tool Tip Text"

    Note: If you use iconButton, the text will show up but you can still use the tooltip.
-->

<script setup>
import { computed } from 'vue';
defineOptions({
    inheritAttrs: false
})
const props = defineProps({
    icon: {
        type: String,
    },
    iconButton: {
        type: Boolean,
        default: false,
    },
    toolTip: {
        type: String,
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const toolTip = computed(() => {
    let toolTip = '';
    if (props.toolTip){
        toolTip = props.toolTip;
    }
    return toolTip;
});

// manage classes
const btnClass = computed(() => {
    let btnClass = 'agile-button prevent-select ';

    btnClass += props.iconButton ? ' uk-icon-button' : ' uk-button';

    return btnClass;
});

</script>
<template>
    <button :class="[{ ['uk-button-primary']: !$attrs.class || !$attrs.class.includes('uk-button-')}, btnClass]" :uk-tooltip="toolTip" v-bind="$attrs">
        <span v-if="props.loading" class="uk-margin-small-right cog cog-white" :uk-icon="'icon: cog; ratio: 1;'"></span>
        <slot v-if="!props.iconButton">
            Agile Button
        </slot>
        <span v-if="props.icon" :uk-icon="'icon:' + props.icon"></span>
    </button>
</template>