<script setup>
import { computed, ref } from 'vue';
import { useDocumentsApi } from '~/src/api/documents';

const emit = defineEmits(['addDocument']);
const props = defineProps({
    document: {
        type: [Object, undefined],
        required: false,
        default(raw) {
            return {
                expiration: '',
                file_location: '',
                isExpired: false,
                name: '',
                vend_no: '',
                zzchar_1: '',
                zzchar_2: '',
                zzdate_1: null,
                zzdec_1: 0,
                zzing_1: 0,
                zzlog_1: false
            }
        }
    },
    expirable: {
        type: Boolean,
        default: false
    },
    template: {
        type: [Array, String, undefined],
        required: false
    }
});

const invalidDocument = computed(() => props.document.isExpired || !props.document.isOnFile);

const documents = useDocumentsApi();

const loadingSignature = ref(false);

const signDocument = async () => {
    loadingSignature.value = true;

    await documents.sign(props.template);

    loadingSignature.value = false;
};
</script>

<template>
    <tr>
        <td class="uk-width-1-3"><slot>{{ document.name }}</slot></td>
        <td class="uk-width-1-3 uk-text-center">
            <span v-if="!document.isOnFile" class="uk-text-danger"><span uk-icon="icon: warning"></span> Not on file</span>
            <template v-else>
                <template v-if="expirable">
                    <span v-if="document.isExpired" class="uk-text-danger"><span uk-icon="icon: warning"></span> Expired {{ new Date(document.expiration).toLocaleDateString("en-US") }}</span>
                    <span v-else class="uk-text-secondary"><span uk-icon="icon: check"></span> Valid until {{ new Date(document.expiration).toLocaleDateString("en-US") }}</span>
                </template>
                <span v-else class="uk-text-secondary"><span uk-icon="icon: check"></span> Valid document on file</span>
            </template>
        </td>
        <td class="uk-width-1-3 uk-text-right">
            <template v-if="expirable">
                <AgileButton v-if="invalidDocument" :loading="loadingSignature" class="uk-button-link" type="button" @click="signDocument">Sign Document<span uk-icon="icon: file-edit" class="uk-margin-small-left"></span></AgileButton>
            </template>
            <template v-else>
                <AgileButton v-if="!document.isOnFile" :loading="loadingSignature" class="uk-button-link" type="button" @click="signDocument">Sign Document<span uk-icon="icon: file-edit" class="uk-margin-small-left"></span></AgileButton>
            </template>
        </td>
    </tr>
</template>