import {useAuthStore} from "./../stores/auth.store.js";

export function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    if (url) {
        const {user, JwtToken} = useAuthStore();
        const baseUrl = process.env.APIURL;
        // const baseUrl = "/api";
        const isLoggedIn = !!user?.RefreshToken;
        // console.log("authHeader isLoggedIn ", isLoggedIn);
        const isApiUrl = url.startsWith(baseUrl);
        // log("authHeader isApiUrl ", isApiUrl);
        // Firefox may require the comma list for Access-Control-Allow-Methods.
        if (isLoggedIn && isApiUrl) {
            const myToken = {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`,
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
            };
            // console.log("authHeader myToken", myToken);
            return myToken;
        } else {
            // console.log("setting basic header");
            const header = {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT",
            }
            return header;
        }
    } else {
        return "error";
    }
}