<script setup>
import { computed, onMounted, ref } from 'vue';
import { useAdminStore } from '~/src/stores/admin.store';
import { useLocationsApi } from '~/src/api/locations';
import AgileStateSelector from '~/src/Components/AgileComponents/AgileStateSelector.vue';
import ManageLocationsRow from '~/src/Components/ManageLocationsRow.vue';
import UIkit from 'uikit';

let modal;

const adminStore = useAdminStore();

const locations = useLocationsApi();

const locationProperties = {
    name: '',
    name_ext: '',
    address: '',
    Addr3: '',
    city: '',
    state: '',
    zip_code: '',
    ph_no: '',
    country: '',
    facility_needinside: false,
    facility_climate: false
};

const location = ref(locationProperties);

const locationSaveMethod = computed(() => location.value.uID_contacts != undefined && location.value.uID_contacts != false ? 'update' : 'create');

const addLocation = () => {
    location.value = locationProperties;

    modal.show();
};

const editLocation = (editingLocation) => {
    location.value = editingLocation;

    modal.show();
}

const fetchLocationsAndHideModal = () => {
    locations.fetch();

    modal.hide();
};

const saveLocation = () => {
    locations[locationSaveMethod.value](location.value).then((response) => {
        if (!response.Data.success) {
            return console.error("Error while adding new location");
        }

        fetchLocationsAndHideModal();
    });
};

onMounted(() => {
    modal = UIkit.modal(document.querySelector('#location-modal'));
});
</script>

<template>
    <div class="uk-container uk-container-large uk-border">
        <div class="uk-padding">
            <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
                <h2 class="uk-margin-remove">Manage Locations</h2>
                <AgileButton type="button" @click="addLocation">Add Location</AgileButton>
            </div>
            <div class="project-list-header uk-visible@m uk-flex uk-flex-between uk-light uk-background-secondary uk-margin-bottom uk-padding-small uk-child-width-1-6">
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Location Name</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Address</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">City</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">State</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Zip Code</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase">Country</div>
                <div class="uk-text-center uk-text-bold uk-text-uppercase"></div>
            </div>
            <ManageLocationsRow :location="adminStore.primaryLocation" @edit-location="editLocation" />
            <ManageLocationsRow v-for="location in adminStore.auxillaryLocations" :key="location.uID_contacts" :location="location" @edit-location="editLocation" />
        </div>
        <div id="location-modal" class="uk-modal-container" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-header uk-padding uk-flex uk-flex-middle uk-flex-between">
                    <h2 class="uk-margin-remove uk-modal-title">
                        <span v-if="locationSaveMethod == 'update'">Edit</span><span v-if="locationSaveMethod == 'create'">Add</span> Location
                    </h2>
                    <button class="uk-modal-close-full uk-close-large uk-border-circle" type="button" uk-close></button>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>
                        <div class="uk-padding uk-padding-remove-vertical">

                            <div class="uk-margin-small-bottom">
                                <label for="legal-name">Legal Name</label>
                                <input type="text" name="legal-name" id="legal-name" class="uk-input uk-margin-remove" v-model="location.name">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="primary-contact">Primary Contact</label>
                                <input type="text" name="primary-contact" id="primary-contact" class="uk-input uk-margin-remove" v-model="location.name_ext">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="address-1">Address Line 1</label>
                                <input type="text" name="address-1" id="address-1" class="uk-input uk-margin-remove" v-model="location.address">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="address-2">Address Line 2</label>
                                <input type="text" name="address-2" id="address-2" class="uk-input uk-margin-remove" v-model="location.Addr3">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="city">City</label>
                                <input type="text" name="city" id="city" class="uk-input uk-margin-remove" v-model="location.city">
                            </div>
                            <div class="uk-flex uk-child-width-1-2" uk-grid>
                                <div class="uk-margin-small-bottom">
                                    <label for="state">State</label>
                                    <AgileStateSelector name="state" id="state" v-model="location.state" required />
                                </div>
                                <div class="uk-margin-small-bottom">
                                    <label for="zip-code">Zip Code</label>
                                    <input type="text" name="zip-code" id="zip-code" class="uk-input uk-margin-remove" v-model="location.zip_code">
                                </div>
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="country">Country</label>
                                <input type="text" name="country" id="country" class="uk-input uk-margin-remove" v-model="location.country">
                            </div>
                            <!-- <div class="uk-flex uk-margin-small-bottom">
                                <label for="indoor-facility"><input type="checkbox" name="indoor-facility" id="indoor-facility" class="uk-checkbox uk-margin-small-right" v-model="location.facility_needinside"> Is this facility indoors?</label>
                            </div>
                            <div class="uk-flex uk-margin-small-bottom">
                                <label for="climate-controlled-facility"><input type="checkbox" name="climate-controlled-facility" id="climate-controlled-facility" class="uk-checkbox uk-margin-small-right" v-model="location.facility_climate"> Is this facility climate controlled?</label>
                            </div> -->

                        </div>
                        <div class="uk-padding uk-padding-remove-vertical">

                            <div class="uk-margin-small-bottom">
                                <label for="phone-number">Phone Number</label>
                                <input type="text" name="phone-number" id="phone-number" class="uk-input uk-margin-remove" v-model="location.ph_no">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="email">Email</label>
                                <input type="text" name="email" id="email" class="uk-input uk-margin-remove" v-model="location.email">
                            </div>
                            <div class="uk-margin-small-bottom">
                                <label for="indoor-facility">Is this an indoor facility?</label>
                                <select name="indoor-facility" id="indoor-facility" class="uk-select" v-model="location.facility_needinside" required>
                                    <option value="">Select One</option>
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                </select>
                            </div>
                            <template v-if="location.facility_needinside">
                                <div class="uk-margin-small-bottom">
                                    <label for="climate-controlled">Is the facility climate controlled?</label>
                                    <select name="climate-controlled" id="climate-controlled" class="uk-select" v-model="location.facility_climate" required>
                                        <option value="">Select One</option>
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                </div>
                                <div class="uk-margin-small-bottom">
                                    <label for="facility-dimensions">Facility Dimensions</label>
                                    <input type="number" name="facility-dimensions" id="facility-dimensions" class="uk-input" v-model="location.facility_size" placeholder="Enter as 'Length x Width'" required>
                                </div>
                            </template>
                            <div class="uk-flex uk-margin-small-bottom uk-child-width-1-2" uk-grid>
                                <div>
                                    <label for="coord-lat">Latitude</label>
                                    <input type="text" class="uk-input" v-model="location.latitude">
                                </div>
                                <div>
                                    <label for="coord-lng">Longitude</label>
                                    <input type="text" class="uk-input" v-model="location.longitude">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="uk-flex uk-flex-right">
                        <AgileButton type="button" @click="saveLocation">Save</AgileButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>