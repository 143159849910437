<script setup>
import { useAdminStore } from '~/src/stores/admin.store';
import { useQualificationsApi } from '~/src/api/qualifications';

const adminStore = useAdminStore();
const qualifications = useQualificationsApi();
</script>

<template>
    <div id="qualifications">
        <div class="uk-flex uk-flex-between uk-flex-middleuk-margin-bottom">
            <h2 class="uk-margin-remove">Qualifications</h2>
            <AgileButton
                @click="qualifications.update"
                :loading="adminStore.loading.qualificationsUpdate"
                class="uk-button-default uk-text-muted"
                type="button">Save Qualifications</AgileButton>
        </div>
        <form action="">
            <div class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>
                <div class="uk-padding uk-padding-remove-vertical">
                    <h3 class="uk-margin-remove-bottom">Applications</h3>

                    <div class="uk-margin-small-left">
                        <div v-for="application in adminStore.qualifications.applications.sort((a, b) => a.tSort - b.tSort)">
                            <label :for="`qual-app-${application.tSeq}`">
                                <input type="checkbox" :name="`qual-app-${application.tSeq}`" :id="`qual-app-${application.tSeq}`" class="uk-checkbox uk-margin-small-right" v-model="application.tQualified"> {{ application.tOption }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="uk-padding uk-padding-remove-vertical">
                    <h3 class="uk-margin-remove-bottom">Removals</h3>
                    <div class="uk-margin-small-bottom uk-margin-small-left">
                        <div v-for="removal in adminStore.qualifications.removals.sort((a, b) => a.tSort - b.tSort)">
                            <label :for="`qual-rem-${removal.tSeq}`">
                                <input type="checkbox" :name="`qual-rem-${removal.tSeq}`" :id="`qual-rem-${removal.tSeq}`" class="uk-checkbox uk-margin-small-right" v-model="removal.tQualified"> {{ removal.tOption }}
                            </label>
                        </div>
                    </div>

                    <h3 class="uk-margin-top uk-margin-remove-bottom">Miscellaneous</h3>
                    <div class="uk-margin-small-left">
                        <div>
                            <label for="qual-other-use-subcontractors">
                                <input type="checkbox" name="qual-other-use-subcontractors" id="qual-other-use-subcontractors" class="uk-checkbox uk-margin-small-right" v-model="adminStore.installer.inst_subcontractors"> Use Subcontractors?
                            </label>
                        </div>
                        <div v-if="adminStore.installer.inst_subcontractors" class="uk-margin-left">
                            <label for="qual-other-directly-manage-subcontractors">
                                <input type="checkbox" name="qual-other-directly-manage-subcontractors" id="qual-other-directly-manage-subcontractors" class="uk-checkbox uk-margin-small-right" v-model="adminStore.installer.directly_manage"> Directly managed?
                            </label>
                        </div>
                        <div>
                            <label for="qual-other-multiple-crews">
                                <input type="checkbox" name="qual-other-multiple-crews" id="qual-other-multiple-crews" class="uk-checkbox uk-margin-small-right" v-model="adminStore.installer.use_multiple_crews"> Multiple Crews?
                            </label>
                        </div>
                        <div>
                            <label for="qual-other-bg-checks-drug-screens">
                                <input type="checkbox" name="qual-other-bg-checks-drug-screens" id="qual-other-bg-checks-drug-screens" class="uk-checkbox uk-margin-small-right" v-model="adminStore.installer.can_provide_screen"> Background checks and drug screens available?
                            </label>
                        </div>
                        <div v-if="adminStore.installer.can_provide_screen" class="uk-margin-left">
                            <label for="qual-other-bg-checks-drug-screens-on-file">
                                <input type="checkbox" name="qual-other-bg-checks-drug-screens-on-file" id="qual-other-bg-checks-drug-screens-on-file" class="uk-checkbox uk-margin-small-right" v-model="adminStore.installer.currently_screen"> On file?
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-3@m" uk-grid>
                <div class="uk-padding uk-padding-remove-vertical">
                    <h3 class="uk-margin-small-top uk-margin-remove-bottom">Certifications</h3>
                    <div class="uk-margin-small-left">
                        <div v-for="certification in adminStore.qualifications.certifications.sort((a, b) => a.tSort - b.tSort)">
                            <label :for="`qual-cert-${certification.tSeq}`">
                                <input type="checkbox" :name="`qual-cert-${certification.tSeq}`" :id="`qual-cert-${certification.tSeq}`" class="uk-checkbox uk-margin-small-right" v-model="certification.tQualified"> {{ certification.tOption }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="uk-padding uk-padding-remove-vertical">
                    <h3 class="uk-margin-small-top uk-margin-remove-bottom">Materials</h3>
                    <div class="uk-margin-small-left">
                        <div v-for="material in adminStore.qualifications.materials.sort((a, b) => a.tSort - b.tSort)">
                            <label :for="`qual-mat-${material.tSeq}`">
                                <input type="checkbox" :name="`qual-mat-${material.tSeq}`" :id="`qual-mat-${material.tSeq}`" class="uk-checkbox uk-margin-small-right" v-model="material.tQualified"> {{ material.tOption }}
                            </label>
                        </div>
                        <div><label for="qual-materials-general-formulations"><input type="checkbox" name="qual-materials-general-formulations" id="qual-materials-general-formulations" class="uk-checkbox uk-margin-small-right"> General Formulations</label></div>
                    </div>
                </div>
                <div class="uk-padding uk-padding-remove-vertical">
                    <h3 class="uk-margin-small-top uk-margin-remove-bottom">Special Equipment</h3>
                    <div class="uk-margin-small-left">
                        <div v-for="special in adminStore.qualifications.specials.sort((a, b) => a.tSort - b.tSort)">
                            <label :for="`qual-spec-${special.tSeq}`">
                                <input type="checkbox" :name="`qual-spec-${special.tSeq}`" :id="`qual-spec-${special.tSeq}`" class="uk-checkbox uk-margin-small-right" v-model="special.tQualified"> {{ special.tOption }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>