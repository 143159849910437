import { defineStore } from 'pinia';

export const useDocumentsStore = defineStore({
    id: 'documents',
    state: () => ({
        all: []
    }),
    getters: {
        installerAgreement(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'agreement') },
        installerQuestionaire(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'other')},
        installationPolicy(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'installation policy') },
        insuranceCertificate(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'liability insurance') },
        w9(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'w9') },
        nda(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'nda') },
        workComp(state) { return state.all.find((doc) => doc.name.toLowerCase() == 'wc/waiver') },
        socialMediaPolicy(state) { return state.all.find((doc) => doc.name.toLowerCase() == '') }
    },
    actions: {
        set(documents) {
            this.all = documents;
        }
    }
})