<script setup>
import { computed } from 'vue';
import { useAdminStore } from '~/src/stores/admin.store';
import { useLocationsApi } from '~/src/api/locations';
import AgileStateSelector from '~/src/Components/AgileComponents/AgileStateSelector.vue';

const adminStore = useAdminStore();
const locations = useLocationsApi();

const entity_types = {
    "SP": "Sole Proprietor",
    "CC": "C Corporation",
    "CS": "S Corporation",
    "JP": "Joint Partnership",
    "TE": "Trust Estate",
    "LC": "LLC C Corporation",
    "LS": "LLC S Corporation",
    "LP": "LLC Partnership"
};

const requireW2Employees = computed(() => ["JP", "TE", "SP"].includes(adminStore.vendor.entity_type));
</script>

<template>
    <div id="primary-location">
        <div class="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
            <h2 class="uk-margin-remove">Primary Location</h2>
            <AgileButton
                @click="() => locations.update(adminStore.primaryLocation)"
                :loading="adminStore.loading.locationUpdate"
                class="uk-button-default uk-text-muted"
                type="button">Save Location Info</AgileButton>
        </div>
        <form action="">
            <div class="uk-flex uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>
                <div class="uk-padding uk-padding-remove-vertical">

                    <div class="uk-margin-small-bottom">
                        <label for="legal-name">Legal Name</label>
                        <input type="text" name="legal-name" id="legal-name" class="uk-input" v-model="adminStore.vendor.name" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="primary-contact">Owner/Primary Contact</label>
                        <input type="text" name="primary-contact" id="primary-contact" class="uk-input" v-model="adminStore.vendor.owner" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="tax-id">Tax ID (with dashes)</label>
                        <input type="text" name="tax-id" id="tax-id" class="uk-input" v-model="adminStore.vendor.tax_no" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="liability-no">Liability Number</label>
                        <input type="text" name="liability-no" id="liability-no" class="uk-input" v-model="adminStore.vendor.Liab_no">
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="entity-type">Entity Type</label>
                        <select name="entity-type" id="entity-type" class="uk-select" v-model="adminStore.vendor.entity_type" required>
                            <option value="">Selecty an Entity Type</option>
                            <option v-for="(label, value) in entity_types" :value="value">{{ label }}</option>
                        </select>
                    </div>
                    <div v-if="requireW2Employees" class="uk-margin-small-bottom">
                        <label for="num-w2-employees">Number of W2 Employees</label>
                        <input type="number" name="num-w2-employees" id="num-w2-employees" class="uk-input" v-model="adminStore.installer.inst_fulltime">
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="manufacture-graphics">Does the company manufacture graphics?</label>
                        <select name="manufacture-graphics" id="manufacture-graphics" class="uk-select" v-model="adminStore.installer.inst_manf_graphics" required>
                            <option value="">Select One</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="installation-facility">Is this an installation facility?</label>
                        <select name="installation-facility" id="installation-facility" class="uk-select" v-model="adminStore.primaryLocation.facility_needinside" required>
                            <option value="">Select One</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                    <div v-if="adminStore.primaryLocation.facility_needinside" class="uk-margin-small-bottom">
                        <label for="climate-controlled-facility">Is the facility climate controlled?</label>
                        <select name="climate-controlled-facility" id="climate-controlled-facility" class="uk-select" v-model="adminStore.primaryLocation.facility_climate" required>
                            <option value="">Select One</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>

                </div>
                <div class="uk-padding uk-padding-remove-vertical">

                    <div class="uk-margin-small-bottom">
                        <label for="address-1">Address Line 1</label>
                        <input type="text" name="address-1" id="address-1" class="uk-input" v-model="adminStore.vendor.addr1" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="address-2">Address Line 2</label>
                        <input type="text" name="address-2" id="address-2" class="uk-input" v-model="adminStore.vendor.addr2">
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="city">City</label>
                        <input type="text" name="city" id="city" class="uk-input" v-model="adminStore.vendor.city" required>
                    </div>
                    <div class="uk-flex uk-child-width-1-2" uk-grid>
                        <div class="uk-margin-small-bottom">
                            <label for="state">State</label>
                            <AgileStateSelector name="state" id="state" v-model="adminStore.vendor.state" required />
                        </div>
                        <div class="uk-margin-small-bottom">
                            <label for="zip-code">Zip Code</label>
                            <input type="text" name="zip-code" id="zip-code" class="uk-input" v-model="adminStore.vendor.zip_code">
                        </div>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="phone-number">Phone Number</label>
                        <input type="text" name="phone-number" id="phone-number" class="uk-input" v-model="adminStore.vendor.phone_no" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="country">Country</label>
                        <input type="text" name="country" id="country" class="uk-input" v-model="adminStore.vendor.country" required>
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" class="uk-input" v-model="adminStore.vendor.email">
                    </div>
                    <div class="uk-margin-small-bottom">
                        <label for="email">Website</label>
                        <input type="url" name="website" id="website" class="uk-input" v-model="adminStore.installer.inst_website">
                    </div>

                </div>
            </div>
        </form>
    </div>
</template>