<script setup>
import {onBeforeMount, onMounted, ref, computed} from 'vue';
import {useAQStore} from '../stores/aq.store.js';
import {router} from '../router/router.js';


const props = defineProps({
  modelValue: {},
  keys: {
    type: Array,
  },
  data: {
    type: Object,
  },
  filter: {
    type: String,
  }
})

// const title = ref('');
const titleLabel = ref('');
const localKeys = ref([]);
const hiddenKeys = ref(['ID', 'Class', 'City', 'State', 'LocID', 'ImageCount']);  // use this list to hide data from the user
const excludedKeys = ref(['']); // use this list to prevent data from being shown to the user
// const excludedKeys = ref(['ID', 'LocID']);
const objectKeys = ref([]);

onBeforeMount(() => {
  // console.log("AQ Object before mount");
  // getTileData(props.data);
  // updateLocalKeys();
  getKeys();
})

onMounted(() => {
  // console.log("AQ Object mounted");
  // console.log("AQ Object data: ", props.data);
})

const updateLocalKeys = computed(() => {
  localKeys.value = [];
  // console.log("Filter: ", props.filter);
  for (const [key, value] of Object.entries(props.data)) {
    if (!hiddenKeys.value.includes(key)) {
      localKeys.value.push(key);
    }
    if (props.filter && checkSearch(key) && !excludedKeys.value.includes(key)) {
      if (!localKeys.value.includes(key)) {
        // console.log("unhiding : ", key, " due to search.");
        localKeys.value.push(key);
      }
    }
  }
  // console.log("Updated Local Keys: ", localKeys.value);
  return localKeys.value;
})


const getKeys = () => {
  let keys = [];
  for (const [key, value] of Object.entries(props.data)) {
    keys.push(key);
  }
  objectKeys.value = keys;
  // console.log("Keys: ", keys);
  return keys;
}

const checkSearch = (item) => {
  // console.log("Item: ", item);
  // console.log("Value: ", props.data[item].toLowerCase(), " Filter: ", props.filter.toLowerCase());
  if (props.data[item].toLowerCase().includes(props.filter.toLowerCase()) && !excludedKeys.value.includes(item)) {

    return true;
  }
  return false;
}

const getObject = (() => {
  // console.log("Getting object: ", props.data);
  if (props.data.ID) {
    useAQStore().setCurrentObjectID(parseInt(props.data.ID));
    localStorage.setItem('currentObjectID', parseInt(props.data.ID));
    router.push({ name: 'unitInformation' });
  }
})

</script>

<template>
  <div class="aq-object uk-height-1-1 uk-padding-remove" :class="props.filter">
    <div
        class="aq-object-card uk-background-default uk-padding-remove uk-height-1-1 uk-flex uk-flex-column"
        @click="getObject">
      <div
          class="uk-height-1-1 uk-padding-small uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom uk-position-relative"
          :class="{'uk-border-success' : props.data['Status'] === 'Installer invoiced' || props.data['Status'] === 'Completed', 
                'uk-border-danger' : props.data['Status'] === 'Pending',
                'lk-border-customer' : props.data['Status'] === 'Customer Approved',
                'uk-border-warning' : props.data['Status'] === 'Awaiting Invoice Approval' || props.data['Status'] === 'Additional'}">

        <!--Missing Images-->
        <div class="uk-position-top-right uk-margin-right" v-if="props.data['ImageCount'] == '0'">
          <span class="uk-text-lead" style="color: red" uk-icon="icon: image; ratio: 0.9">!</span>
        </div>
        <!--End Missing Images-->

        <div v-for="key in updateLocalKeys">
          <div v-if="props.data[key]" class="uk-margin-remove uk-padding-small uk-padding-remove-top">
            <p class="uk-margin-remove uk-padding-remove uk-text-truncate">
              <span class="uk-text-bold">{{ key }}:&nbsp;</span>
              <span :class="{'search-result' : props.filter && checkSearch(key)}">{{ props.data[key] }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>