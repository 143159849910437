import { ref } from 'vue';

export const useCounties = (initValue) => {
    const add = () => list.value.push(newCounty());

    const append = (county) => list.value.push(county);

    const clear = () => list.value = [];

    const remove = (index) => list.value.splice(index, 1);

    const newCounty = () => ({
        zone_seq: ref(null),
        county: ref(null)
    });

    const list = ref(initValue || []);

    return {
        list,
        add,
        append,
        clear,
        newCounty,
        remove
    }
};