import { useAdminApi } from '~/src/api/admin';
import { useAdminStore } from '~/src/stores/admin.store';

export const useLocationsApi = () => {
    const adminStore = useAdminStore();
    const { apiUrl, makeApiRequest } = useAdminApi();

    const create = async (location) => {
        const loadingKey = 'locationCreate';
        adminStore.toggleLoading(loadingKey);

        if (!location.cust_no) {
            location.cust_no = `APF.${adminStore.installerId}`;
        }

        const url = apiUrl('Locations/Create');
        const responseJson = await makeApiRequest(url, { location });

        adminStore.toggleLoading(loadingKey);

        return responseJson;
    }

    const fetch = async () => {
        const url = apiUrl('Locations/Fetch');
        const responseJson = await makeApiRequest(url);

        adminStore.set('locations', responseJson.Data.locations);

        return responseJson;
    };

    const update = async (location) => {
        const loadingKey = 'locationUpdate';
        adminStore.toggleLoading(loadingKey);

        const url = apiUrl('Locations/Update');
        const responseJson = await makeApiRequest(url, { location });

        adminStore.toggleLoading(loadingKey);

        return responseJson;
    };

    return {
        create,
        fetch,
        update
    };
};