const baseUrl = 'https://atlas.microsoft.com/search';

const endpoints = {
    'address': `${baseUrl}/address/json`,
    'fuzzy': `${baseUrl}/fuzzy/json`
};

export function useMapSearch(AZURE_MAPS_KEY) {
    const defaultSearchParams = {
        'api-version': '1.0',
        'subscription-key': AZURE_MAPS_KEY,
        'language': 'en-US',
    };

    const forAddress = async (address) => {
        console.info("Searching for address...");
        console.debug(address);
        const queryAddress = typeof address == 'string' ? address : `${address.address}, ${address.city}, ${address.state}`;
        console.debug(queryAddress);

        const results = await searchQuery(endpoints.address, new URLSearchParams({
            ...defaultSearchParams,
            query: queryAddress
        }));

        console.info("Results returned from address search");
        console.debug(results);

        return results;
    }
    
    const forState = async (state) => {
        console.info("Searching for state...");
        console.debug(state);

        const results = await searchQuery(endpoints.fuzzy, new URLSearchParams({
            ...defaultSearchParams,
            'countrySet': 'US',
            'entityType': 'CountrySubdivision',
            'query': state
        }));

        console.info("Results returned from state search");
        console.debug(results);

        return results;
    };

    const searchQuery = (url, params) => {
        const searchQueryUrl = new URL(`${url}?${params.toString()}`);

        return fetch(searchQueryUrl)
            .then((response) => response.json())
            .then((json) => {
                if (json.summary.totalResults == 0) {
                    console.error('Azure Maps Search query return no results.');
                }

                return json;
            });
    };

    return {
        forAddress,
        forState,
    }
};