<script setup>
import {onBeforeMount, ref, watch, computed} from 'vue';
import {useAQStore} from '../stores/aq.store.js';
import {router} from '../router/router.js';

const props = defineProps({
  modelValue: {},
  index: {
    type: Number
  },
  function: {
    type: Function
  },
  data: {
    type: Object
  },
  filter: {
    type: String
  },
  caller: {
    type: String
  }
})
function isNotEmpty (test) {
  // console.log("isNotEmpty", test);
  let returnValue = false;
  if (Array.isArray(test)) {
    returnValue = test.length > 0;
  } else if (typeof test === 'object' && test !== null) {
    returnValue = Object.keys(test).length > 0;
  } else {
    if (test !== null && test !== "" && test !== undefined && test !== 'undefined')
      returnValue = true;
  }
  return returnValue;
}
let index = ref(props.index);
let data = ref(props.data);
const localKeys = ref([]);
const hiddenKeys = ref(['UniqueIDs', 'ItemGroupCharges', 'Charges', 'ID', 'LocID', 'grpSeq', 'locSeq', 'ObjectID', 'locCity', 'locState', 'GroupCharge', 'iSeq']);
const excludedKeys = ref(['UniqueIDs', 'ItemGroupCharges', 'Charges', 'ID', 'LocID', 'grpSeq', 'locSeq', 'ObjectID', 'GroupCharge', 'iSeq']);

// TESTING BLOCIK
// const hiddenKeys = ref(['UniqueIDs', 'ItemGroupCharges', 'Charges', 'ID', 'grpClass', 'ObjectID', 'locCity', 'locState', 'GroupCharge']);
// const excludedKeys = ref(['UniqueIDs', 'ItemGroupCharges', 'Charges', 'ID', 'grpClass', 'ObjectID', 'GroupCharge']);


const displayNameList = ref({
  grpType: "Type",
  grpClass: "Class",
  locName: "Location",
  locCity: "City",
  locState: "State",
  iSeq: "Object",
  ChargeAmount: "Amount",
  ChargeType: "Type",
  ChargeQuantity: "Charge Quantity",
})

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

onBeforeMount(() => {
  index = props.index;
  // console.log("Mount InvoiceObject")
  // getKeys();
  // formatCharges();
})
const selected = ref(false);

const keys = computed(() => {
  let keys = [];
  for (const [key, value] of Object.entries(props.data)) {
    if (!keys.includes(key)) {
      keys.push(key);
    }
  }
  return keys;
})

const changeQty = (event) => {
  event.stopPropagation();
}

const toggleSelected = () => {
  // console.log("IO toggleSelected");
  // if create invoice modal
  if (props.caller) {
    // console.log("toggleSelected caller", props.caller);
    // console.log("toggleSelected index", index);
    selected.value = !selected.value;
    return props.function(props.data);
  } else {
    // console.log("toggleSelected Getting invoice object");
    // console.log("groupCharge", props.data.GroupCharge);
    if (props.data.GroupCharge === false) {
      getObject();
    }
  }
}

const getObject = (() => {
  // console.log("Getting object: ", props.data);
  useAQStore().setCurrentObjectID(parseInt(props.data.ObjectID));
  localStorage.setItem('currentObjectID', parseInt(props.data.ObjectID));
  router.push({ name: 'unitInformation' });
  // if (props.data.ID){
  //     useAQStore().setCurrentObjectID(parseInt(props.data.ID));
  // }
});

const checkSearch = (item) => {
  let search = props.filter.toLowerCase();
  // console.log("Filter: ", search);
  let searchItem = props.data[item].toString().toLowerCase();
  // console.log("Search Item: ", searchItem);
  // console.log("Checking search: ", search, " against: ", searchItem);
  if (search && searchItem.includes(search)) {
    // console.log("Found: ", searchItem);
    return true;
  }
  return false;
}

const updateLocalKeys = computed(() => {
  // console.log("updateLocalKeys");
  localKeys.value = [];
  let iData = data.value;

  if (iData.Charges) {
    iData.Charges.forEach(charge => {
      let keys = Object.keys(charge);
      keys.forEach(key => {
        iData[key] = charge[key];
      })
    })
  }
  // console.log("iData: ", iData);

  // console.log("Filter: ", props.filter);
  if (!props.data.GroupCharge) {
    //Object Charges
    for (const [key, value] of Object.entries(iData)) {
      if (!hiddenKeys.value.includes(key) && props.data[key] && props.data[key] != null && props.data[key] != "" && key != "ChargeAmount" && key != "ChargeType") {
        // console.log('Pushing key: ', key, ' to local keys.');
        localKeys.value.push(key);
      }
      if (props.filter && checkSearch(key) && !excludedKeys.value.includes(key)) {
        if (!localKeys.value.includes(key)) {
          // console.log("unhiding : ", key, " due to search.");
          localKeys.value.push(key);
        }
      }
    }
  } else {
    // Group Charges
    for (const [key, value] of Object.entries(iData)) {
      // console.log("running updateLocalKeys group charges");
      // Reset ChargeQuantity to 1 for Group Charges. It should not be 0.
      if (key === "ChargeQuantity") {
        // console.log(props.data);
        if (props.data[key] < 1) {
          // console.log("setting key ", key);
          props.data[key] = 1;
        }
      }
      if (!hiddenKeys.value.includes(key) && props.data[key] && props.data[key] != null && props.data[key] != "") {
        // if (!hiddenKeys.value.includes(key) && props.data[key] != null) {
        // console.log('Pushing key: ', key, ' to local keys.');
        localKeys.value.push(key);
      }
      if (props.filter && checkSearch(key) && !excludedKeys.value.includes(key)) {
        if (!localKeys.value.includes(key)) {
          // console.log("unhiding : ", key, " due to search.");
          localKeys.value.push(key);
        }
      }
    }
    // reverse key list for group charge to show amount last
    localKeys.value = localKeys.value.reverse();
  }
  // console.log("Updated Local Keys: ", localKeys.value);
  return localKeys.value;
});

const changeDisplayName = (key) => {
  // console.log("changeDisplayName: ", key);
  if (displayNameList.value[key]) {
    return true;
  }
  return false;
}

const toCurrency = (amount) => {
  amount = formatter.format(amount)
  return amount;
}

const shouldShowKey = (key) => {
  if (key != 'ChargeAmount' && key != 'ChargeType' && data.value[key]) {
    // console.log("Key: ", key, " should show.");
    return true;
  }
  if (data.value.GroupCharge) {
    return true;
  }

  return false;
}

watch(() => props.modelValue, (value) => {
  // console.log("value", value);
  selected.value = value;
})
</script>

<template>
  <div v-if="props.data"
       @click="toggleSelected()"
       :class="{'invoice-object-container uk-padding-small uk-background-default uk-flex uk-margin-small-bottom' : {},
        'invoice-object-selected' : selected,
        'invoice-object' : props.caller, ' invoice-object-default': props.data.GroupCharge === false}"
  >
    <div
        class="invoice-details uk-margin-small-bottom uk-flex uk-flex-middle uk-width-expand uk-child-width-1-1 uk-margin-remove-bottom">
      <span v-if="props.data.GroupCharge" class="uk-text-bold uk-text-center uk-flex uk-flex-middle uk-flex-center">Group Charges</span>
      <div v-for="key in updateLocalKeys">
        <div v-if="props.data[key] && shouldShowKey(key) || props.data[key] == 0 && shouldShowKey(key)"
             class="io-key-column">
          <span v-if="changeDisplayName(key)" class="uk-text-bold">{{ displayNameList[key] }}:&nbsp;</span>
          <!--Header -->
          <span v-else class="uk-text-bold">{{ key }}:&nbsp;</span>
          <!--Value -->
          <span v-if="key != 'ChargeAmount'"
                :class="{'uk-text-uppercase uk-text-center': key, 'search-result': props.filter && checkSearch(key)}">
                    <span v-if="key === 'ChargeQuantity'">
                      <input class="uk-input uk-form-width-small" type="number" min="1" v-model="props.data[key]"
                             v-on:click="changeQty">
                    </span>
                    <span v-else>{{ props.data[key] }}</span>
                  </span>

          <!--Charge Amount-->
          <span v-else style="color: red;"
                :class="{'uk-text-uppercase': key, 'search-result': props.filter && checkSearch(key)}">{{
              toCurrency(props.data[key])
            }}</span>
        </div>
      </div>
      <div v-if="isNotEmpty(props.data.Charges)" class="uk-padding-small uk-padding-remove-vertical">
        <p class="uk-margin-remove uk-text-bold uk-text-center">Services</p>
        <div v-for="charge in data.Charges" class="invoice-object-body uk-flex uk-flex-column uk-text-center">
          <p class="uk-margin-remove uk-text-uppercase">
            <span :class="{'search-result' : props.filter && checkSearch('ChargeType')}">{{
                charge.ChargeType
              }}: </span>
            <span style="color: red;" :class="{'search-result' : props.filter && checkSearch('ChargeAmount')}">{{
                toCurrency(charge.ChargeAmount)
              }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>