import { useAdminApi } from '~/src/api/admin';
import { useAuthStore } from '~/src/stores/auth.store';
import { useDocumentsStore } from '~/src/stores/documents.store';
import { router } from '~/src/router/router';
import HelloSign from 'hellosign-embedded';

export const DOC_TEMPLATES = [
    'InstallerAgreement',
    'InstallerPolicy',
    'W9',
    'InstallerQuestionnaire',
    'NDA',
    'WorkersComp',
    'SocialMediaAgreement'
];

export const useDocumentsApi = () => {
    const authStore = useAuthStore();
    const docStore = useDocumentsStore();

    const { apiUrl, makeApiRequest } = useAdminApi();

    const helloSign = new HelloSign();

    const fetch = async () => {
        const url = apiUrl('Documents/Fetch');
        const responseJson = await makeApiRequest(url);
        
        docStore.set(responseJson.Data.docs);
    };

    const showHelloSignDocument = (hsOptions) => {
        helloSign.on('sign', (data) => console.dir(data));
        helloSign.open(hsOptions.sign_url, {
            clientId: hsOptions.client_id,
            skipDomainVerification: true
        });
    };

    const sign = async (document) => {
        let signingDocument;

        if (Array.isArray(document)) {
            signingDocument = document;
        } else if (typeof document == 'string') {
            signingDocument = [document];
        } else if (typeof document == 'undefined' || document == false) {
            signingDocument = DOC_TEMPLATES;
        }

        for (const doc of signingDocument) {
            if (!DOC_TEMPLATES.includes(doc)) {
                return console.error('Invalid document template name');
            }
        }

        const url = apiUrl('Documents/Sign');
        const responseJson = await makeApiRequest(url, {
            'documents': signingDocument,
            'signer_email': authStore.user.Email,
            'signer_name': authStore.user.CustomerName
        });

        showHelloSignDocument(responseJson.Data);

        return responseJson;
    };

    return {
        fetch,
        sign
    };
};