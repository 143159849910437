import { useAdminApi } from '~/src/api/admin';
import { useAdminStore } from '~/src/stores/admin.store';

export const useInstallerApi = () => {
    const adminStore = useAdminStore();
    const { apiUrl, makeApiRequest } = useAdminApi();

    const fetch = async () => {
        const url = apiUrl('Installer/Fetch');
        const responseJson = await makeApiRequest(url);

        adminStore.set('installer', responseJson.Data.installer);
        adminStore.set('vendor', responseJson.Data.vendor);
    };

    return {
        fetch
    }
};