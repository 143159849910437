import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAdminStore = defineStore({
    id: 'admin',
    state: () => ({
        coverage_types: [],
        documents: [],
        installer: {},
        locations: [],
        qualifications: {
            certifications: [],
            specials: [],
            applications: [],
            removals: [],
            materials: []
        },
        users: [],
        unit_types: [],
        vendor: {},
        zones: [],
        loading: {
            locationCreate: ref(false),
            locationUpdate: ref(false),
            qualificationsUpdate: ref(false),
            zoneDelete: ref(false),
            zoneFetch: ref(false),
            zoneSave: ref(false),
        }
    }),
    getters: {
        auxillaryLocations(state) {
            if (state.locations.length == 0) return [];

            return state.locations.filter((contact) => contact.master_shiploc != "location");
        },
        expiredDocuments(state) {
            if (state.documents.length == 0) return [];

            return state.documents.filter((document) => document.isExpired);
        },
        hasExpiredDocuments(state) {
            if (state.documents.length == 0) return false;

            return state.documents.some((document) => document.isExpired);
        },
        installerId(state) {
            return state.installer.vend_no ?? state.vendor.vend_no ?? "";
        },
        primaryLocation(state) {
            if (state.locations.length == 0) return {};

            return state.locations.find((contact) => contact.master_shiploc = "location");
        },
        primaryAddress(state) {
            const primaryLocation = state.primaryLocation;

            return `${primaryLocation.address}, ${primaryLocation.city}, ${primaryLocation.state}`;
        }
    },
    actions: {
        getLocationByKey(contactKey) {
            if (!this.locations.length) return null;

            return this.locations.find((location) => location.ContactKey == contactKey);
        },
        set(property, data) {
            if (!Object.hasOwn(this, property)) {
                console.error(`Cannot set '${property}' property on admin store`);
            }

            this[property] = data;
        },
        toggleLoading(key) {
            if (!Object.hasOwn(this.loading, key)) {
                return console.error(`Loading state with key '${key}' does not exist`);
            }

            this.loading[key] = !this.loading[key];
        },
        resizeTo() {
            this.$reset();
        }
    }
})